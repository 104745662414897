import React, { useState, useRef, useContext, useEffect } from "react";
import { getJamtrack } from "../services/jamtracks";
import AuthContext from "./auth-context";
import { roundToNearestQuarter } from "../utils";
import mediaItemTypes from "../enums/media-item-types";

const MusicPlayerContext = React.createContext({
  current: null,
  isPlaying: false,
  isAudioLoading: false,
  queue: [],
  history: [],
  currentNote: null,
  trackProgress: 0,
  trackDuration: 0,
  setTrackProgress: () => { },
  setTrackDuration: () => { },
  startNew: () => { },
  play: () => { },
  pause: () => { },
  toNext: () => { },
  toPrev: () => { },
  setCurrent: () => { },
  setCurrentNote: () => { },
  addToQueue: () => { },
  removeFromQueue: () => { },
  resetQueue: () => { },
  addToHistory: () => { },
  removeFromHistory: () => { },
});

export const MusicPlayerContextProvider = (props) => {
  // NOTE: The isPlayTriggered hack is a way to allow the current audio player to
  // work on mobile, as audio playing has to be triggered by an onClick event.
  // Next step will be to move all audio controls and state into here...

  const { token } = useContext(AuthContext);

  const initialQueue = JSON.parse(localStorage.getItem("queue")) || [];
  const [current, setCurrent] = useState(null);
  const [queue, setQueue] = useState(initialQueue);
  const [history, setHistory] = useState([]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [trackDuration, setTrackDuration] = useState(0);
  const [currentNote, setCurrentNote] = useState(null);

  const audioRef = useRef(new Audio());
  const intervalRef = useRef();

  const startNewTrackHandler = async (mediaItem) => {
    setIsPlaying(false);
    audioRef.current.pause();
    addToHistoryHandler(mediaItem); // TODO filter it out of history, then add it in
    setIsAudioLoading(true);
    setCurrentNote(null);
    if (mediaItem?.type !== mediaItemTypes.JAMTRACK) {
      setCurrent(mediaItem);
      setIsPlaying(true);
      setIsAudioLoading(false);

      // Progress bar will just show 0 progress since tracking isn't possible
      setTrackProgress(0);
    } else {
      // TODO try catch block?
      const updated = await getJamtrack(token, mediaItem.id);
      setCurrent({ ...updated, type: mediaItemTypes.JAMTRACK });

      audioRef.current.src = updated.track;
      audioRef.current.play();
      setTrackProgress(0);
      setIsPlaying(true);

      audioRef.current.addEventListener("canplay", () => {
        setTrackDuration(audioRef.current.duration); // track must be playing before duration is available
        setIsAudioLoading(false);
        // startTimer(updated.transcription);
      });
    }
  };

  const playHandler = () => {
    setIsPlaying(true);
    if (current && current?.type === mediaItemTypes.JAMTRACK) {
      audioRef.current.play();
    }
    // resume interval
  };

  const pauseHandler = () => {
    setIsPlaying(false);
    if (current && current?.type === mediaItemTypes.JAMTRACK) {
      audioRef.current.pause();
    }
    // TODO pause interval
  };

  const toNextHandler = () => {
    if (queue.length > 0) {
      startNewTrackHandler(queue[0]);
      removeFromQueueHandler(0);
    } else {
      setIsPlaying(false);
      setCurrent(null);
    }
  };

  const toPrevHandler = () => {
    startNewTrackHandler(history.pop());
    removeFromHistoryHandler(0);
  };

  const addToQueueHandler = (jamtrack, index = null) => {
    let newQueue;
    if (index) {
      newQueue = [
        ...queue.slice(0, index),
        jamtrack,
        ...queue.slice(index + 1),
      ];
    } else {
      newQueue = [...queue, jamtrack];
    }
    localStorage.setItem("queue", JSON.stringify(newQueue));
    setQueue(newQueue);
  };

  const removeFromQueueHandler = (index) => {
    const updatedQueue = [...queue.slice(0, index), ...queue.slice(index + 1)];
    localStorage.setItem("queue", JSON.stringify(updatedQueue));
    setQueue(updatedQueue);
  };

  const resetQueueHandler = () => {
    localStorage.setItem("queue", JSON.stringify([]));
    setQueue([]);
  };

  const addToHistoryHandler = (mediaItem) => {
    if (mediaItem?.type === mediaItemTypes.JAMTRACK) {
      const newHistory = [mediaItem, ...history];
      setHistory(newHistory);
    }
  };

  const removeFromHistoryHandler = (index) => {
    const updatedQueue = [...queue.slice(1)];
    setQueue(updatedQueue);
  };

  const changeTrackProgressHandler = (seconds) => {
    setTrackProgress(seconds);
    if (current && current?.type === mediaItemTypes.JAMTRACK) {
      audioRef.current.currentTime = seconds;
    }
  };

  useEffect(() => { // handles track progress
    if (current && current?.type === mediaItemTypes.JAMTRACK) {
      const transcription = current.transcription;
      intervalRef.current = setInterval(() => {
        if (audioRef.current.ended) {
          toNextHandler();
        } else {
          setTrackProgress(audioRef.current.currentTime);
          const time = roundToNearestQuarter(audioRef.current.currentTime);
          if (transcription && transcription[time]) {
            setCurrentNote(transcription[time]);
          }
        }
      }, [250]);
    }

    return () => clearInterval(intervalRef.current);
  }, [isPlaying, queue]);

  const contextValue = {
    current: current,
    queue: queue,
    history: history,
    isPlaying: isPlaying,
    isAudioLoading: isAudioLoading,
    currentNote: currentNote,
    trackProgress: trackProgress,
    trackDuration: trackDuration,
    setTrackProgress: changeTrackProgressHandler,
    setTrackDuration: setTrackDuration,
    startNew: startNewTrackHandler,
    play: playHandler,
    pause: pauseHandler,
    toNext: toNextHandler,
    toPrev: toPrevHandler,
    setCurrent: (item) => setCurrent(item),
    setCurrentNote: setCurrentNote,
    addToQueue: addToQueueHandler,
    resetQueue: resetQueueHandler,
    removeFromQueue: removeFromQueueHandler,
    addToHistory: addToHistoryHandler,
    removeFromHistory: removeFromHistoryHandler,
  };

  return (
    <MusicPlayerContext.Provider value={contextValue}>
      {props.children}
    </MusicPlayerContext.Provider>
  );
};

export default MusicPlayerContext;
