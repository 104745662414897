import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/auth-context";
import { Button, Col, Row, Typography } from "antd";
import { listHighlights } from "../../services/faqs";
import { listJamtracks } from "../../services/jamtracks";
import { listPlayalongs } from "../../services/yt-playalongs";
import JamtrackCard from "../../components/cards/jamtrack-card";
import BlogCard from "../../components/cards/blog-card/blog-card";
import ChordProgCard from "../../components/cards/chord-prog-card/chord-prog-card";
import { genreMap } from "../../enums/genres";
import { listBlogs } from "../../services/blogs";
import { listChordProgs } from "../../services/chord-progressions";
import FeedContext from "../../contexts/feed-context";
import HomeSkeleton from "./home-skeleton";
import HeroCardCarousel from "../../components/hero-card-carousel/hero-card-carousel";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import { getAdFrequency } from "../../utils";
import InFeedAd from "../../components/ads/in-feed-ad/in-feed-ad";
import YTPlayalongCard from "../../components/cards/yt-playalong-card/yt-playalong-card";

const HomePage = () => {
  const {
    token,
  } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext); // jamtracks

  const [highlights, setHighlights] = useState([]);
  const [chordProgs, setChordProgs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [playalongs, setPlayalongs] = useState([]);
  const [allResults, setAllResults] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [hasError, setHasError] = useState(false);

  const loadData = async () => {
    setHasError(false);

    if (allResults.length === 0) {
      setIsLoading(true);
    } else {
      setIsPaginationLoading(true);
    }

    try {
      const {
        results: highlightData
      } = await listHighlights(null, { category: "home" });
      const {
        results: jamtrackData,
        next: hasMoreJamtracks
      } = await listJamtracks(token, {
        no_bass: false,
        no_drums: false,
        offset: allResults.filter((r) => r.type === "jamtrack").length,
        limit: 5,
      });
      const {
        results: chordProgData,
        next: hasMoreChordProgs
      } = await listChordProgs(token, {
        offset: chordProgs.length,
        limit: 5,
      });
      const {
        results: blogsData,
        next: hasMoreBlogs
      } = await listBlogs(token, {
        offset: blogs.length,
        is_published: true,
        limit: 5,
      });
      const {
        results: playalongsData,
        next: hasMorePlayalongs
      } = await listPlayalongs(token, {
        offset: playalongs.length,
        is_transcribed: true,
        limit: 5,
      });

      const newCombinedResults = [
        ...jamtrackData.map((jt) => ({ ...jt, type: "jamtrack" })),
        ...blogsData.map((b) => ({ ...b, type: "blog" })),
        ...chordProgData.map((cp) => ({ ...cp, type: "chordProg" })),
        ...playalongsData.map((p) => ({ ...p, type: "playalong" }))
      ].sort((a, b) => new Date(b.created) - new Date(a.created));

      const newResults = [...allResults, ...newCombinedResults];

      setHighlights(highlightData);

      // keep track of counts to properly paginate
      setFeed([...feed, ...jamtrackData]);
      setBlogs([...blogs, ...blogsData]);
      setChordProgs([...chordProgs, ...chordProgData]);
      setPlayalongs([...playalongs, ...playalongsData]);

      setAllResults(newResults);
      setCanPaginate(
        !!hasMoreJamtracks || !!hasMoreBlogs || !!hasMoreChordProgs || !!hasMorePlayalongs
      );
    } catch {
      console.log("error loading data");
      setHasError(true);
    } finally {
      setIsLoading(false);
      setIsPaginationLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const favoriteIds = [...feed.filter((j) => j.is_favorite)].map((j) => j.id)
    setAllResults(allResults.map((r) => {
      if (r.type !== "jamtrack") {
        return r
      } else {
        return {
          ...r,
          is_favorite: favoriteIds.includes(r.id)
        }
      }
    }))
  }, [feed]);

  if (isLoading) return <HomeSkeleton />;

  return (
    <>

      {highlights.length > 0 && (
        <>
          <HeroCardCarousel highlights={highlights} />
        </>
      )
      }

      <br />

      <Typography.Title level={4}>Recently Added</Typography.Title>
      <Row gutter={[12, 12]}>
        {allResults.map((r, i) => (
          <>
            {r.type === "jamtrack" && (
              <Col span={24}>
                <JamtrackCard jamtrack={r} />
              </Col>
            )}
            {r.type === "blog" && (
              <Col span={24}>
                <BlogCard blog={r} />
              </Col>
            )}
            {r.type === "chordProg" && (
              <Col span={24}>
                <ChordProgCard chordProg={r} onDelete={() => console.log("Gross")} />
              </Col>
            )}
            {r.type === "playalong" && (
              <Col span={24}>
                <YTPlayalongCard ytPlayalong={r} />
              </Col>
            )}
            {
              (i + 1) % getAdFrequency() == 0 && (
                <InFeedAd key={r.id} />
              )
            }
          </>
        ))}

        {isPaginationLoading && (
          <>
            {[1, 2, 3].map((i) => {
              return (
                <Col key={i} span={24}>
                  <SkeletonJamtrackCard />
                </Col>
              );
            })}
          </>
        )}
      </Row>
      {
        canPaginate && !isPaginationLoading && (
          <>
            <br />
            <Row justify="center">
              <Col>
                <Button onClick={loadData} type="link">
                  Load More
                </Button>
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
};

export default HomePage;
