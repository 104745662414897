import React from "react";
import { Typography } from "antd";
import UserProfilePopover from "../user-profile-popover/user-profile-popover";
import GrayIconSvg from "../custom-icons/gray-icon-svg";
import mediaItemTypes from "../../enums/media-item-types";

const NowPlaying = ({ currentTrack }) => {
  return (
    <div
      size="small"
      style={{
        marginTop: 14,
        marginBottom: 14,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!currentTrack ? (
        <GrayIconSvg />
      ) : (
        <>
          <div>
            <Typography.Text
              strong
              style={{ display: "block", lineHeight: "100%", width: "100%" }}
            >
              {currentTrack.title}
            </Typography.Text>
          </div>
          {currentTrack.type !== mediaItemTypes.YT_CHORD_EXTRACTOR ?
            < div style={{ display: "block", lineHeight: "100%" }}>
              <Typography.Link type="secondary">
                <UserProfilePopover
                  user={currentTrack.user}
                  isFollowing={currentTrack.is_following}
                >
                  {currentTrack.user.username}
                </UserProfilePopover>
              </Typography.Link>
            </div>
            :
            <div style={{ display: "block", lineHeight: "100%" }}>
              <Typography.Text type="secondary">
                {currentTrack.user.username}
              </Typography.Text>
            </div>
          }
        </>
      )
      }
    </div >
  );
};

export default NowPlaying;
