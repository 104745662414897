const baseUrl = process.env.REACT_APP_BASE_URL;

export const listTools = async (token, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/tools/${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const getTool = async (token, id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/tools/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const toggleToolFavorite = async (token, tool) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify({
      is_favorite: !tool.is_favorite,
    }),
  };

  const url = `${baseUrl}/api/tools/${tool.id}/favorite/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const createYTChordExtraction = async (token, task) => {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(task),
  };

  const url = `${baseUrl}/api/youtube/yt-chord-extractor-task/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    const json = await response.json();
    throw Error(JSON.stringify(json));
  }
  return response.json();
};

export const getYTChordExtraction = async (token, id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/youtube/yt-chord-extractor-task/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const listYTChordExtractions = async (token, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/youtube/yt-chord-extractor-task/${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};


export const getYouTubeRemainingTokens = async (token) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/youtube/yt-chord-extractor-task/remaining/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};