import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, notification } from "antd";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import AuthContext from "../../contexts/auth-context";
import { getPlayalong } from "../../services/yt-playalongs";
import YTPlayalongCard from "../../components/cards/yt-playalong-card/yt-playalong-card";

const YTPlayalongDetailPage = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const [playalong, setPlayalong] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { playalongId } = useParams();

  const loadData = async () => {
    setIsLoading(true);
    try {
      const playalongData = await getPlayalong(token, playalongId);
      setPlayalong(playalongData);
    } catch {
      notification.open({
        message: "We didn't find what your looking for...",
        description: "We are redirecting you to the home page.",
        placement: "bottomRight",
      });
      navigate("/");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Row gutter={[0, 12]} justify="center">
      <Col span={24}>
        {isLoading ? (
          <SkeletonJamtrackCard />
        ) : (
          <YTPlayalongCard ytPlayalong={playalong} />
        )}
      </Col>
    </Row>
  );
};

export default YTPlayalongDetailPage;
