import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  Space,
  Tag,
  Typography,
  Avatar,
  theme,
  Row,
  Col,
  Tooltip,
  notification,
  Modal,
  Collapse,
} from "antd";
import {
  LoadingOutlined,
  CaretRightFilled,
  PauseOutlined,
  GlobalOutlined,
  KeyOutlined,
  FieldTimeOutlined,
  RetweetOutlined,
  TableOutlined,
  OrderedListOutlined,
  HeartFilled,
  HeartOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { genreMap } from "../../enums/genres";
import { keysMap, tonalityMap } from "../../enums/keys";
import MusicPlayerContext from "../../contexts/music-player-context";
import AuthContext from "../../contexts/auth-context";
import JamtrackActionButtons from "../jamtrack-action-buttons/jamtrack-action-buttons";
import UserProfilePopover from "../user-profile-popover/user-profile-popover";
import { capitalizeWord, getFrettyQueryString } from "../../utils";
import FeedContext from "../../contexts/feed-context";
import {
  toggleJamtrackFavorite,
} from "../../services/jamtracks";
import { Key, Note, Chord } from "tonal";
import { tonalMap } from "../../enums/tonal";
import { default as ReactChord } from "@tombatossals/react-chords/lib/Chord";
import { guitarChords, guitar } from "../../chords";
import { tonalToReactChordMap } from "../../enums/tonalToReactChords";
import mediaItemTypes from "../../enums/media-item-types";

const ChordCharts = ({ note, tonality }) => {
  return (
    <>
      <Row>
        {guitarChords.chords[tonalToReactChordMap[note]]
          .find((n) => n.suffix === tonality)
          .positions.slice(0, 4)
          .map((chord) => (
            <Col span={12}>
              <ReactChord chord={chord} instrument={guitar} />
            </Col>
          ))}
      </Row>
    </>
  )
}

const JamtrackCard = ({ jamtrack }) => {
  const {
    token: { colorInfoBg },
  } = theme.useToken();
  const {
    current,
    setCurrent,
    addToQueue,
    isPlaying,
    isAudioLoading,
    startNew,
    pause,
    play,
  } = useContext(MusicPlayerContext);
  const { isLoggedIn, token, tokenClaim } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [scaleModalVisible, setScaleModalVisible] = useState(false);
  const [currentScale, setCurrentScale] = useState({});
  const [chordModalVisible, setChordModalVisible] = useState(false);
  const [currentChord, setCurrentChord] = useState({});

  const isCurrent = current && current.id === jamtrack.id;

  const tonic = tonalMap[jamtrack.key]
  const type = jamtrack.tonality
  const jamtrackKey = type === "minor" ? Key.minorKey(tonic).natural : Key.majorKey(tonic)

  const handleChordClick = (chord) => {
    const { tonic, aliases } = Chord.get(chord);
    const simplifiedTonic = Note.simplify(tonic)
    const tonality = aliases[0]

    setChordModalVisible(true);
    setCurrentChord({ note: simplifiedTonic, tonality })
  }

  const handleScaleClick = (scale) => {
    setScaleModalVisible(true);
    setCurrentScale({ name: scale, queryString: getFrettyQueryString(scale, jamtrack.no_bass) })
  }

  const formatTonalChordName = (chordName) => {
    const { tonic, aliases } = Chord.get(chordName);
    return Note.simplify(tonic) + aliases[0]
  }

  const formatTonalScaleName = (scaleName) => {
    const [tonic, type] = scaleName.split(" ")
    return Note.simplify(tonic) + " " + capitalizeWord(type)
  }

  const addTrackToQueue = (jamtrack) => {
    addToQueue(jamtrack);
    notification.open({
      message: "Added to Queue",
      placement: "bottomRight",
    });
  };

  const handleFavoriteClick = async () => {
    try {
      await toggleJamtrackFavorite(token, jamtrack);

      const newFeed = feed.map((track) => {
        if (track.id === jamtrack.id) {
          return {
            ...track,
            is_favorite: !jamtrack.is_favorite,
          };
        }
        return track;
      });

      const newCurrent =
        current && current.user.id === jamtrack.user.id
          ? { ...current, is_favorite: !jamtrack.is_favorite }
          : null;

      if (newCurrent) setCurrent(newCurrent);
      setFeed(
        pathname !== "/favorites"
          ? newFeed
          : newFeed.filter((t) => t.is_favorite)
      );
      notification.open({
        message: `Jam Track ${jamtrack.is_favorite ? "removed from" : "added to"} favorites.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTranscribedIcon = () => {
    if (jamtrack.hide_transcription) return null;
    if (
      !jamtrack.is_transcribed &&
      jamtrack.user.username === tokenClaim.username
    ) {
      return (
        <Tooltip title="Awaiting transcription">
          <Typography.Text type="secondary">
            <LoadingOutlined />
          </Typography.Text>
        </Tooltip>
      );
    }
    return null;
  };

  const getPlayButton = () => {
    if (isCurrent && isAudioLoading) {
      return (
        <Space>
          <LoadingOutlined />
        </Space>
      );
    }
    if (!isCurrent) {
      return (
        <div onClick={() => startNew({ ...jamtrack, type: mediaItemTypes.JAMTRACK })}>
          <Tooltip title="Play">
            <CaretRightFilled />
          </Tooltip>
        </div>
      );
    }

    if (isCurrent && isPlaying) {
      return (
        <div onClick={() => pause()}>
          <Tooltip title="Pause">
            <PauseOutlined />
          </Tooltip>
        </div>
      );
    }

    return (
      <div onClick={() => play()}>
        <CaretRightFilled />
      </div>
    );
  };

  const getActionButtons = () => {
    const actions = [
      getPlayButton(),
      <div onClick={() => addTrackToQueue({ ...jamtrack, type: mediaItemTypes.JAMTRACK })}>
        <Tooltip title="Add to Queue">
          <OrderedListOutlined />
        </Tooltip>
      </div>,
    ];

    if (isLoggedIn) {
      actions.push(
        <div onClick={handleFavoriteClick}>
          {jamtrack.is_favorite ?
            <HeartFilled /> :
            <HeartOutlined />}
        </div>
      );
    }

    return actions;
  };

  const chords = jamtrackKey.chords.map((c) =>
    <small>
      <a
        onClick={() => handleChordClick(c)}>{formatTonalChordName(c)}
      </a>
      {c === jamtrackKey.chords[jamtrackKey.chords.length - 1] ? "" : ", "}
    </small>)


  const chordScales = jamtrackKey.chordScales.map((cs) =>
    <small>
      <a
        onClick={() => handleScaleClick(cs)}>{formatTonalScaleName(cs)}
      </a>
      {cs === jamtrackKey.chordScales[jamtrackKey.chordScales.length - 1] ? "" : ", "}
    </small>)

  return (
    <Card size="small" actions={getActionButtons()}>
      <Row justify="space-between">
        <Col span={20}>
          <Space align="start" size="middle">
            <UserProfilePopover
              user={jamtrack.user}
              isFollowing={jamtrack.in_following_feed}
            >
              <Avatar
                shape="square"
                size="large"
                src={jamtrack.user.photo}
              >
                {jamtrack.user.username[0]}
              </Avatar>
            </UserProfilePopover>
            <Space direction="vertical" size="middle">
              <div>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {jamtrack.title}
                </Typography.Title>
                <Space
                  split={<Typography.Text type="secondary">•</Typography.Text>}
                >
                  <Typography.Link type="secondary" onClick={() => navigate("/jamtracks/lead")}>
                    <Space>
                      <small>
                        <CustomerServiceOutlined />
                      </small>
                      <small>
                        Jam Track
                      </small>
                    </Space>
                  </Typography.Link>
                </Space>
              </div>
            </Space>
          </Space>
        </Col>
        <Col>
          <div style={{ textAlign: "right" }}>
            <Space>
              {getTranscribedIcon()}
              <JamtrackActionButtons jamtrack={jamtrack} />
            </Space>
          </div>
        </Col>
      </Row>
      {jamtrack.description && (
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <Typography.Text>{jamtrack.description}</Typography.Text>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Space wrap>
            <Tag bordered={false} icon={<GlobalOutlined />}>
              {genreMap[jamtrack.genre]}
            </Tag>
            <Tag bordered={false} icon={<KeyOutlined />}>
              {`${keysMap[jamtrack.key]} ${tonalityMap[jamtrack.tonality]}`}
            </Tag>
            <Tag bordered={false} icon={<FieldTimeOutlined />}>
              {jamtrack.tempo} BPM
            </Tag>
            <Tag bordered={false} icon={<RetweetOutlined />}>
              {jamtrack.time_sig} TIME
            </Tag>
            {jamtrack.is_transcribed && !jamtrack.hide_transcription && (
              <Tag bordered={false} icon={<TableOutlined />}>
                Live Chords
              </Tag>
            )}
            {jamtrack.no_bass && (
              <Tag color="blue" bordered={false}>
                For Bass Players
              </Tag>
            )}
            {jamtrack.no_drums && (
              <Tag color="blue" bordered={false}>
                For Drummers
              </Tag>
            )}
          </Space>
        </Col>
        {isCurrent && jamtrack.no_drums === false && (
          <Col span={24}>
            <div
              style={{
                marginTop: isCurrent ? 10 : 0,
              }}
            >
              <Collapse
                size="small"
                expandIconPosition="end"
                bordered={false}
                style={{ backgroundColor: colorInfoBg }}
              >
                <Collapse.Panel
                  header={
                    <small>
                      {`Chords and scales for the key of ${tonic} ${capitalizeWord(type)}`}
                    </small>
                  }
                >
                  <div>
                    <div>
                      <small>
                        <b>Key of {tonic} {capitalizeWord(type)}: </b>
                        {jamtrackKey.scale.map((n) => Note.simplify(n)).join(" → ")}
                      </small>
                    </div>
                    <div>
                      <small>
                        <b>Chords: </b>
                      </small>
                      {chords}
                    </div>
                    <div>
                      <small>
                        <b>Chord Scales: </b>
                      </small>
                      {chordScales}
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
            <Modal
              title={formatTonalScaleName(currentScale?.name || "") + " Scale"}
              width="100%"
              footer={null}
              open={scaleModalVisible}
              onCancel={() => setScaleModalVisible(false)}
            >
              <iframe src={"https://fretty.jamtrackers.com" + currentScale?.queryString} height="250" width="100%" style={{ border: "none" }}></iframe>
            </Modal>
            <Modal
              title={`Chords for ${currentChord?.note + currentChord?.tonality}`}
              footer={null}
              open={chordModalVisible}
              onCancel={() => setChordModalVisible(false)}
            >
              {currentChord && <ChordCharts note={currentChord.note} tonality={currentChord.tonality} />}
            </Modal>
          </Col>
        )}
      </Row>
    </Card >
  );
};

export default JamtrackCard;
