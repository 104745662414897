import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
  Upload,
  message,
  Modal,
  notification,
  Radio,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CommentOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { createBlog, deleteBlog, getBlog, updateBlog } from "../../services/blogs";
import AuthContext from "../../contexts/auth-context";
import styles from "./blog-edit.module.scss";
import Dragger from "antd/es/upload/Dragger";
import { convertToBase64, isValidYouTubeUrl } from "../../utils";
import BlogPreview from "./components/blog-preview";
import FeedHeader from "../../components/typography/feed-header";
import VideoPlayer from "../../components/video-player/video-player";
import mediaItemTypes from "../../enums/media-item-types";

const BlogEditLoading = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 1 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 3 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 10 }} />
        </Card>
      </Col>
    </Row>
  );
};

const SectionCardTitle = ({ title, desc }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      <Typography.Text type="secondary">
        <small>{desc}</small>
      </Typography.Text>
    </div>
  );
};

const BlogEditPage = () => {
  const navigate = useNavigate();
  const { blogId } = useParams();
  const { token, tokenClaim } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [previewOn, setPreviewOn] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [photo, setPhoto] = useState(null);
  const [newPhotoData, setNewPhotoData] = useState(null);
  const [videoSetting, setVideoSetting] = useState("no_video");
  const [videoYoutubeUrl, setVideoYoutubeUrl] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const editOrNewTitle = blogId ? "Edit Blog" : "New Blog";
  const videoOptions = [
    {
      label: 'No Video',
      value: 'no_video',
    },
    {
      label: 'YouTube',
      value: 'youtube',
    },
  ];

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const blogData = await getBlog(token, blogId);

      if (blogData.user.username !== tokenClaim.username) {
        navigate("/");
      }

      const {
        title,
        description,
        video_setting: savedVideoSetting,
        video_youtube_url: savedYTUrl,
        content,
        photo,
        is_published
      } = blogData;

      setTitle(title);
      setDescription(description);
      setVideoSetting(savedVideoSetting);
      setVideoYoutubeUrl(savedYTUrl);
      setContent(content);
      setPhoto(photo);
      setIsPublished(is_published);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCoverPhotoSelect = async (file) => {
    const acceptedMimeTypes = ["image/jpeg", "image/png"];
    const isJpgOrPng = acceptedMimeTypes.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return;
    }

    const base64 = await convertToBase64(file);
    setNewPhotoData(base64);
    return false; // prevent it from making upload call
  };

  const togglePreviewMode = () => {
    setPreviewOn(!previewOn);
  };

  const onSaveBlog = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const blogData = {
        title,
        description,
        content,
        video_setting: videoSetting,
        video_youtube_url: videoYoutubeUrl,
        is_published: isPublished,
      };

      if (blogId) {
        if (newPhotoData) blogData.photo = newPhotoData;
        await updateBlog(token, blogId, blogData);
      } else {
        blogData.photo = newPhotoData;
        await createBlog(token, blogData);
      }

      notification.open({
        message: "Blog Saved Successfully",
        placement: "bottomRight",
      });
      navigate(isPublished ? "/blogs/recent" : "/blogs/drafts");
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to save this blog at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    try {
      await deleteBlog(token, { id: blogId });
      notification.open({
        message: "Blog Deleted",
        description:
          `${title} has been deleted.`,
        placement: "bottomRight",
      });
      navigate(`/blogs/`);
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to delete this blog at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    if (blogId) {
      loadData();
    } else {
      setIsLoading(false);
    }
  }, [blogId]);

  if (hasError) {
    return (
      <Alert
        type="error"
        message="Something went wrong..."
        description="An error occured, we are unable to load the blog. Please try again
      later."
      />
    );
  }
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <FeedHeader icon={<CommentOutlined />} title={editOrNewTitle} />
        </Col>
        <Col>
          <Space>
            <Typography.Text type="secondary">Preview Mode</Typography.Text>
            <Switch checked={previewOn} onChange={togglePreviewMode} />
          </Space>
        </Col>
      </Row>
      {isLoading ? (
        <BlogEditLoading />
      ) : (
        <>
          {previewOn ? (
            <BlogPreview
              title={title}
              desc={description}
              content={content}
              videoSetting={videoSetting}
              videoYoutubeUrl={videoYoutubeUrl}
              img={newPhotoData || photo}
            />
          ) : (
            <Form
              initialValues={{
                remember: true,
                title,
                description,
                videoYoutubeUrl,
                content,
              }}
              onFinish={onSaveBlog}
            >
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Card size="small">
                    <Typography.Title level={5}>Title</Typography.Title>
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please add a title.",
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength={400}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Description"
                      desc="A brief description of your blog that entices users to read it."
                    />
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please add a description.",
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength={500}
                        style={{ marginBottom: 12 }}
                        showCount
                        autoSize
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Cover Photo"
                      desc="A decorative image to accompany your blog."
                    />
                    <>
                      {newPhotoData || photo ? (
                        <>
                          <img
                            src={newPhotoData || photo}
                            alt={title}
                            className={styles["blog-cover-photo"]}
                          />
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <Upload
                              name="avatar"
                              showUploadList={false}
                              beforeUpload={handleCoverPhotoSelect}
                              accept="image/png, image/jpeg"
                            >
                              <Button type="link" size="small">
                                Change Photo
                              </Button>
                            </Upload>
                          </div>
                        </>
                      ) : (
                        <Dragger
                          showUploadList={false}
                          beforeUpload={handleCoverPhotoSelect}
                          accept="image/png, image/jpeg"
                        >
                          <Typography.Text type="secondary">
                            Select Photo
                          </Typography.Text>
                        </Dragger>
                      )}
                    </>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Video"
                      desc="Optional: A video to accompany the blog. If provided, the video will be shown in place of the cover image."
                    />
                    <div style={{ textAlign: "center" }}>
                      <Radio.Group
                        options={videoOptions}
                        onChange={(e) => setVideoSetting(e.target.value)}
                        value={videoSetting}
                        optionType="button"
                        style={{ width: "100%" }}
                      />
                    </div>
                    {videoSetting === "youtube" && (
                      <>
                        <br />
                        <Typography.Text type="secondary">
                          <small>Insert the YouTube URL below. If the video fails to appear then the link is invalid.</small>
                        </Typography.Text>
                        <Form.Item
                          name="videoYoutubeUrl"
                          rules={[
                            {
                              required: videoSetting === "youtube",
                              message: "Please add a YouTube URL.",
                            },
                          ]}
                          style={{ margin: 0 }}
                        >
                          <Input
                            value={videoYoutubeUrl}
                            onChange={(e) => setVideoYoutubeUrl(e.target.value)}
                          />
                        </Form.Item>
                        {videoYoutubeUrl && isValidYouTubeUrl(videoYoutubeUrl) && (
                          <div style={{ marginTop: 10 }}>
                            <VideoPlayer
                              src={videoYoutubeUrl}
                              mediaItem={{
                                title: title,
                                user: { username: "Work in Progress" },
                                type: mediaItemTypes.BLOG,
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Content"
                      desc={
                        <>
                          The content of your blog, written in{" "}
                          <a
                            href="https://www.markdownguide.org/basic-syntax/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            markdown syntax
                          </a>
                          .
                        </>
                      }
                    />
                    <Form.Item
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Please add some content.",
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        maxLength={100000}
                        style={{ marginBottom: 12 }}
                        showCount
                        autoSize={{ minRows: 10 }}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <Row justify="space-between" align="middle">
                      <Col xs={24} lg={16}>
                        <SectionCardTitle
                          title="Publish"
                          desc={
                            isPublished
                              ? "This blog will be published and available for others to read. Toggle the switch below and click save to mark it as a draft."
                              : "This blog will be saved as a draft, only visible to you. Toggle the switch below and click save to publish it."
                          }
                        />
                      </Col>
                      <Col span="auto">
                        <Switch
                          checked={isPublished}
                          onChange={() => setIsPublished((prev) => !prev)}
                          checkedChildren="PUBLISHED"
                          unCheckedChildren="DRAFT"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Row justify="space-between">
                    <Col>
                      {blogId && (
                        <>
                          <Button danger onClick={() => setDeleteModal(true)} type="text">
                            Delete Blog
                          </Button>
                          <Modal
                            open={deleteModal}
                            title="Delete Blog"
                            onCancel={() => setDeleteModal(false)}
                            onOk={onConfirmDelete}
                            okText="Delete"
                            okType="danger"
                          >
                            <Typography.Text>Are you sure you want to delete the blog <b>{title}</b>?</Typography.Text>
                          </Modal>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Space>
                        <Button onClick={() => navigate("/blogs/recent")}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={
                            videoSetting === "youtube" && !isValidYouTubeUrl(videoYoutubeUrl)
                          }
                        >
                          Save
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default BlogEditPage;
