import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Space,
  Typography,
  Avatar,
  Row,
  Col,
  notification,
  Dropdown,
  Button,
  Tag,
} from "antd";
import {
  HeartFilled,
  HeartOutlined,
  MoreOutlined,
  ShareAltOutlined,
  EditOutlined,
  GlobalOutlined,
  TableOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import UserProfilePopover from "../../user-profile-popover/user-profile-popover";
import VideoPlayer from "../../video-player/video-player";
import mediaItemTypes from "../../../enums/media-item-types";
import { togglePlayalongFavorite } from "../../../services/yt-playalongs";
import AuthContext from "../../../contexts/auth-context";
import { genreMap } from "../../../enums/genres";

const YTPlayalongCard = ({ ytPlayalong }) => {
  const {
    token,
    tokenClaim,
    isLoggedIn
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(ytPlayalong.is_favorite)

  const handleFavoriteClick = async () => {
    try {
      await togglePlayalongFavorite(token, ytPlayalong);
      setIsFavorited((prev) => !prev);
      notification.open({
        message: `YouTube Play Along ${isFavorited ? "Unfavorited" : "Favorited"}`,
        description:
          `${ytPlayalong.title} has been ${ytPlayalong.is_favorite ? "removed from" : "added to"} your list of favorites.`,
        placement: "bottomRight",
      });
    } catch (error) {
      notification.open({
        message: "Uh oh, something went wrong.",
        description:
          "We were unable to add or remove this to your favorites. Please try again later",
        placement: "bottomRight",
      });
    }
  };

  const getActionButtons = () => {
    const actions = [];

    if (isLoggedIn) {
      actions.push(
        <div onClick={handleFavoriteClick}>
          {isFavorited ? <HeartFilled /> : <HeartOutlined />}
        </div>
      );
    }

    return actions;
  };

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(hostname + `/yt-playalongs/${ytPlayalong.id}`);
    notification.open({
      message: "Copied to Clipboard",
      description:
        "A direct link to this jamtrack has been copied to your clipboard.",
      placement: "bottomRight",
    });
  };

  const moreActions = [
    {
      key: "share",
      label: "Share",
      icon: <ShareAltOutlined />,
      onClick: handleClickShare,
    },
  ];

  if (isLoggedIn && ytPlayalong.user.username === tokenClaim.username) {
    moreActions.push(
      {
        key: "edit",
        label: "Edit",
        icon: <EditOutlined />,
        onClick: () => navigate(`/yt-playalongs/${ytPlayalong.id}/edit`),
      },
    )
  }

  return (
    <Card
      size="small"
      actions={getActionButtons()}
    >
      <Row justify="space-between">
        <Col span={20}>
          <Space align="start" size="middle">
            <UserProfilePopover
              user={ytPlayalong.user}
              isFollowing={ytPlayalong.in_following_feed}
            >
              <Avatar
                shape="square"
                size="large"
                src={ytPlayalong.user.photo}
              >
                {ytPlayalong.user.username[0]}
              </Avatar>
            </UserProfilePopover>
            <Space direction="vertical" size="middle" wrap>
              <div>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {ytPlayalong.title}
                </Typography.Title>
                <Space
                  split={<Typography.Text type="secondary">•</Typography.Text>}
                >
                  <Typography.Link type="secondary" onClick={() => navigate("/yt-playalongs/recent")}>
                    <Space>
                      <small>
                        <YoutubeOutlined />
                      </small>
                      <small>
                        YouTube Play Along
                      </small>
                    </Space>
                  </Typography.Link>
                </Space>
              </div>
            </Space>
          </Space>
        </Col>
        <Col>
          <>
            <Dropdown
              trigger="click"
              menu={{
                items: moreActions,
              }}
            >
              <Button icon={<MoreOutlined />} type="text" si />
            </Dropdown>
          </>
        </Col>
      </Row>
      {ytPlayalong.description && (
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <Typography.Text>{ytPlayalong.description}</Typography.Text>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Space wrap>
            {ytPlayalong.genre && (
              <Tag bordered={false} icon={<GlobalOutlined />}>
                {genreMap[ytPlayalong.genre]}
              </Tag>
            )}
            <Tag bordered={false} icon={<TableOutlined />}>
              Live Chords
            </Tag>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <VideoPlayer
            src={ytPlayalong.url}
            mediaItem={{ ...ytPlayalong, type: mediaItemTypes.PLAYALONG }}
          />
        </Col>
      </Row>
    </Card >
  );
};

export default YTPlayalongCard;
