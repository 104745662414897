import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Typography, Card, theme, Select, Button } from "antd";
import { guitarChords, guitar } from "../../chords";
import { capitalizeWord, formatFromChordDb } from "../../utils";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { getInitialParamsDict } from "../../utils";

const ChordSelectors = ({ note, onChangeNote, tonality, onChangeTonality }) => {
  const keyOptions = [
    ...Object.keys(guitarChords.chords).map((key) => {
      return {
        value: key,
        label: formatFromChordDb(key),
      };
    }),
  ];

  const tonalityOptions = note
    ? guitarChords.chords[note].map((suf) => {
      return {
        value: suf.suffix,
        label: formatFromChordDb(suf.suffix),
      };
    })
    : [];

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} lg={12}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Note"
          defaultValue={note}
          value={note}
          onChange={(value, option) => onChangeNote(value)}
          allowClear
          options={keyOptions}
        />
      </Col>
      <Col xs={24} lg={12}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Tonality"
          defaultValue={tonality}
          value={tonality}
          onChange={(value, option) => onChangeTonality(value)}
          allowClear
          options={tonalityOptions}
          disabled={!note}
        />
      </Col>
    </Row>
  );
};

const ChordPlacements = ({ note, tonality }) => {
  const chordPlacements =
    note && tonality
      ? guitarChords.chords[note].find((n) => n.suffix === tonality).positions
      : [];

  if (!note || !tonality) return null;
  return (
    <>
      <Typography.Title level={5}>
        Placements for {formatFromChordDb(note)} {capitalizeWord(tonality)}
      </Typography.Title>
      <Card>
        <Row gutter={[8, 8]}>
          {chordPlacements.map((c, index) => (
            <Col xs={24} md={12}>
              <Chord chord={c} instrument={guitar} />
            </Col>
          ))}
        </Row>
      </Card>
      <br />
    </>
  );
};

const ChordExplorer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialParams = getInitialParamsDict(location.search);

  const [note, setNote] = useState(initialParams.note);
  const [tonality, setTonality] = useState(initialParams.tonality);

  const onChangeNote = (newNote) => {
    const newFilters = {
      note: newNote,
      tonality,
    };
    const newParamString = new URLSearchParams(newFilters).toString();
    const newUrl =
      location.pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setNote(newNote);
  };

  const onChangeTonality = (newTonality) => {
    const newFilters = {
      note,
      tonality: newTonality,
    };
    const newParamString = new URLSearchParams(newFilters).toString();
    const newUrl =
      location.pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setTonality(newTonality);
  };

  const handleClear = () => {
    setNote(null);
    setTonality(null);
    window.history.replaceState(null, "", location.pathname);
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <Card size="small">
          <Typography.Title level={4}>Chord Explorer</Typography.Title>
          <Typography.Paragraph>
            Chord Explorer lets you navigate a wide selection of guitar
            chords. Simply select a note and tonality in order to find
            various chord placements.
          </Typography.Paragraph>
          <ChordSelectors
            note={note}
            tonality={tonality}
            onChangeNote={onChangeNote}
            onChangeTonality={onChangeTonality}
          />

          {note && tonality && (
            <Row justify="center">
              <Col span={24}>
                <br />
                <ChordPlacements note={note} tonality={tonality} />
              </Col>
              <Col>
                <Button onClick={handleClear} type="link">Reset Chords</Button>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ChordExplorer;
