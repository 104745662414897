import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Dropdown,
  notification,
} from "antd";
import UserProfilePopover from "../../user-profile-popover/user-profile-popover";
import {
  MoreOutlined,
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
  EditOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import styles from "./blog-card.module.scss";
import AuthContext from "../../../contexts/auth-context";
import { toggleBlogFavorite } from "../../../services/blogs";

const ActionButtons = ({ isOwner, blog }) => {
  const navigate = useNavigate();

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(hostname + `/blogs/${blog.id}`);

    notification.open({
      message: "Copied to Clipboard",
      description:
        "A direct link to this blog has been copied to your clipboard.",
      placement: "bottomRight",
    });
  };

  const items = [
    {
      key: "share",
      label: "Share",
      icon: <ShareAltOutlined />,
      onClick: handleClickShare,
    },
  ];

  if (isOwner) {
    items.push(
      {
        key: "edit",
        label: "Edit",
        icon: <EditOutlined />,
        onClick: () => navigate(`/blogs/${blog.id}/edit`),
      },
    );
  }

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          items,
        }}
      >
        <Button icon={<MoreOutlined />} type="text" size="medium" />
      </Dropdown>
    </>
  );
};

const BlogCard = ({ blog }) => {
  const navigate = useNavigate();
  const { isLoggedIn, token, tokenClaim } = useContext(AuthContext);

  const [isFavorite, setIsFavorite] = useState(blog.is_favorite);

  const isBlogOwner = tokenClaim.username === blog.user.username;

  const getActionButtons = () => {
    const actions = [];
    const isPublisher = blog.user.username === tokenClaim.username;
    const favoriteIcon = isFavorite ? <HeartFilled /> : <HeartOutlined />;
    const clickAction =
      isPublisher && !blog.is_published
        ? () => navigate(`/blogs/${blog.id}/edit`)
        : handleFavoriteClick;

    if (isLoggedIn) {
      actions.push(
        <div onClick={clickAction}>
          {isPublisher && !blog.is_published ? <EditOutlined /> : favoriteIcon}
        </div>
      );
    }

    return actions;
  };

  const handleFavoriteClick = async () => {
    try {
      await toggleBlogFavorite(token, blog);

      setIsFavorite((fav) => !fav);

      notification.open({
        message: blog.is_favorite ? "Blog Unfavorited" : "Blog Favorited",
        description: `This blog has been ${blog.is_favorite ? "removed" : "added"
          } to your favorites list.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card size="small" actions={getActionButtons()}>
      <Row justify="space-between">
        <Col span={20}>
          <Space align="start" size="middle">
            <UserProfilePopover
              user={blog.user}
              isFollowing={blog.in_following_feed}
            >
              <Avatar
                shape="square"
                size="large"
                src={blog.user.photo}
              >
                {blog.user.username[0]}
              </Avatar>
            </UserProfilePopover>
            <Space direction="vertical" size="middle">
              <div>
                <Link to={`/blogs/${blog.id}`}>
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    <Space className={styles["blog-title"]}>{blog.title}</Space>
                  </Typography.Title>
                </Link>

                <Space
                  split={<Typography.Text type="secondary">•</Typography.Text>}
                >
                  <Typography.Link type="secondary" onClick={() => navigate("/blogs/recent")}>
                    <Space>
                      <small>
                        <CommentOutlined />
                      </small>
                      <small>
                        Blog
                      </small>
                    </Space>
                  </Typography.Link>
                  {!blog.is_published && (
                    <Typography.Text type="secondary">
                      <small>DRAFT</small>
                    </Typography.Text>
                  )
                  }
                </Space>
              </div>
            </Space>
          </Space>
        </Col>
        <Col>
          <div style={{ textAlign: "right" }}>
            <ActionButtons
              blog={blog}
              isOwner={isBlogOwner}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Typography.Text>{blog.description}</Typography.Text>
        </Col>
        {blog.photo && (
          <Col span={24}>
            <img
              className={styles["blog-card-photo"]}
              src={blog.photo}
              style={{ height: 200, width: "100%", objectFit: "cover" }}
              alt={blog.title}
            />
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default BlogCard;
