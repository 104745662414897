import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Typography, Card, theme, Button, Alert, Input, notification, Spin, Space } from "antd";
import Container from "../../components/layout/container/container";
import MusicPlayerContext from "../../contexts/music-player-context";
import FooterLinks from "../../components/footer-links/footer-links";
import { beginVerification, confirmVerification } from "../../services/authentication";
import AuthContext from "../../contexts/auth-context";

const VerifyPage = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  const { token, logout } = useContext(AuthContext);
  pause(); // stop playing music when page loads

  const [buttonPressed, setButtonPressed] = useState(false);
  const [code, setCode] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const sendVerificationCode = async () => {
    setErrorMsg('');
    setCode('');
    setIsLoading(true);
    setCountdown(90);
    try {
      const data = await beginVerification(token);
      if (data.status !== 202) {
        setErrorMsg(data.msg)
      } else {
        setButtonPressed(true);
      }
    } catch {
      setErrorMsg('An unknown error occured and we are unable to verify your account right now. Please try again later');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmVerificationCode = async () => {
    setErrorMsg('');
    setCode('');
    setIsLoading(true);
    try {
      const data = await confirmVerification(token, code);
      if (data.status !== 200) {
        setErrorMsg(data.msg);
      } else {
        notification.open({
          message: "Account verified successfully!",
          description:
            "Log into Jamtrackers to start jamming.",
          placement: "bottomRight",
        });
        logout();
        navigate('/login');
      }
    } catch {
      setErrorMsg("An unknown error occured and we are unable to verify your account right now. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  // Step 2: useEffect to handle countdown logic
  useEffect(() => {
    if (countdown <= 0) return; // Exit if countdown reaches 0

    // Step 3: Set up a timer that decreases countdown every second
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Step 4: Clear the timer when countdown reaches 0 or component unmounts
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <Container>
      <div
        style={{
          padding: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Row justify="center">
          <Col xs={24} md={18} lg={12}>
            <Card>
              <div style={{ textAlign: "center" }}>
                <Typography.Title
                  level={2}
                  style={{ color: colorPrimary }}
                  className="brand-title"
                >
                  Jamtrackers
                </Typography.Title>
              </div>
              <Typography.Title level={4}>Account Verification</Typography.Title>
              <Typography.Paragraph>
                {buttonPressed ? (
                  "Enter the verification code that you received via email."
                ) : (
                  "Click the button below to begin the verification process. We'll send a verification code to the email address you provided."
                )}
              </Typography.Paragraph>

              <div style={{ textAlign: "center" }}>

                {!buttonPressed ? (
                  <Row gutter={[12, 12]} justify="center">
                    {errorMsg ? (
                      <Col span={24}>
                        <Alert type="warning" message={errorMsg} />
                      </Col>
                    ) : (
                      <Col span={24}>
                        <Button onClick={sendVerificationCode} type="primary" size="large">Send Verification Code</Button>
                      </Col>
                    )
                    }
                  </Row>

                ) : (
                  <Row gutter={[12, 12]} justify="center">
                    <Col span={24}>
                      <Input size="large" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Enter verification code..." />
                    </Col>
                    {errorMsg &&
                      <>
                        <Col span={24}>
                          <Alert type="warning" message={errorMsg} />
                        </Col>
                      </>
                    }
                    <Col span={24}>
                      <Button onClick={confirmVerificationCode} type="primary" size="large">Verify Account</Button>
                    </Col>
                    <Col span={24}>
                      <Button disabled={countdown > 0} onClick={sendVerificationCode} type="link" size="small">
                        <Space>
                          Didn't get it? Click here to send another code.
                          {countdown > 0 &&
                            <>
                              {`(${countdown}s)`}
                            </>
                          }
                        </Space>
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </Card>
            <br />
            <FooterLinks />
          </Col>
        </Row>
      </div>
    </Container >
  );
};

export default VerifyPage;