import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth-context";
import { Row, Col, Typography, Card, Button, Modal, notification, Space } from "antd";
import { CheckCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { deleteUser } from "../../services/users";
import FeedHeader from "../../components/typography/feed-header";
const AccountSettingsPage = () => {
  const navigate = useNavigate();
  const {
    token,
    tokenClaim: {
      user_id: userId,
      is_creator: isCreator,
      is_verified: isVerified,
    },
    logout,
  } = useContext(AuthContext)

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteUser(token, { id: userId });
      logout();
      navigate("/");
      notification.open({
        message: "Account Deleted",
        description: "Your account has been successfully deleted. We are sorry to see you go!",
        placement: "bottomRight",
      });
    } catch (error) {
      notification.open({
        message: "An Error Occurred",
        description: "We are unable to delete your account. Please contact support for help.",
        placement: "bottomRight",
      });
    } finally {
      setShowDeleteModal(false);
    }
  }
  return (
    <>
      <FeedHeader title="Account Settings" icon={<SettingOutlined />} />
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card size="small">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>Account Verification</Typography.Title>
            <Typography.Paragraph type="secondary">
              <small>
                Confirm your email in order to verify your account.
              </small>
            </Typography.Paragraph>
            <div style={{ textAlign: "end" }}>
              {isVerified ? (
                <Typography.Text type="secondary">
                  <Space>
                    Verified
                    <CheckCircleOutlined />
                  </Space>
                </Typography.Text>
              ) : (
                <Button onClick={() => navigate("/verification")}>Verify</Button>
              )}
            </div>
          </Card>
        </Col>

        {!isCreator && (
          <Col span={24}>
            <Card size="small">
              <Typography.Title level={5} style={{ marginBottom: 0 }}>Become a Creator</Typography.Title>
              <Typography.Paragraph type="secondary">
                <small>
                  Learn about how you can publish your own jamtracks, blogs, and courses on Jamtrackers.
                </small>
              </Typography.Paragraph>
              <div style={{ textAlign: "end" }}>
                <Button onClick={() => navigate("/creator-application")}>Learn More</Button>
              </div>
            </Card>
          </Col>
        )}

        <Col span={24}>
          <Card size="small">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>Delete Account</Typography.Title>
            <Typography.Paragraph type="secondary">
              <small>
                Deleting your account is permanent. All uploaded and favorited data will be lost forever.
              </small>
            </Typography.Paragraph>
            <div style={{ textAlign: "end" }}>
              <Button onClick={() => setShowDeleteModal(true)} danger>Delete Account</Button>
            </div>
            <Modal
              open={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onOk={handleDelete}
              okButtonProps={{ danger: true }}
              okText="Yes, Delete Account"
              cancelText="No, Cancel"
              title="Are you sure?"
            >
              Deleting your account is permanent. All data will be removed entirely from Jamtrackers.
            </Modal>
          </Card>
        </Col>
      </Row >
    </>
  )
}

export default AccountSettingsPage;