import React, { useContext, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  theme,
  Spin,
  Alert,
  List,
} from "antd";
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import { getToken } from "../../services/authentication";
import AuthContext from "../../contexts/auth-context";
import FooterLinks from "../../components/footer-links/footer-links";
import { createUser } from "../../services/users";
import { capitalizeWord } from "../../utils";

const RegisterPage = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const recaptchaRef = useRef();

  const handleUsernameChange = async (e) => {
    const insertedUsername = e.target.value.toLowerCase();
    setUsername(insertedUsername);
  };

  const handleCaptcha = (key) => {
    setCaptcha(key);
  };

  const formSumbitHandler = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      if (password !== passwordConfirm) {
        setErrorMessage("The passwords do not match.");
      } else {
        await createUser({
          username,
          email,
          password,
          captcha,
        });

        const response = await getToken({
          username,
          password,
        });

        authCtx.login(response.access);
        navigate("/verification");

        setUsername("");
        setEmail("");
        setPassword("");
        setPasswordConfirm("");
        setCaptcha("");
      }
    } catch (error) {
      const errors = JSON.parse(error.message);
      const errorJsx = (
        <>
          {Object.keys(errors).map((k) => {
            return (
              <div>
                <b>{capitalizeWord(k)}</b>
                <List
                  size="small"
                  dataSource={errors[k]}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </div>
            );
          })}
        </>
      );
      setErrorMessage(errorJsx);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div style={{
        display: "block",
      }}>
        <Card>
          <Row justify="center">
            <Col>
              <Typography.Title
                level={2}
                style={{ color: colorPrimary }}
                className="brand-title"
              >
                Jamtrackers
              </Typography.Title>
            </Col>
          </Row>
          <Form
            size="large"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={formSumbitHandler}
          >
            <Row justify="center">
              <Col span={24}>
                <Typography.Title level={4}>Sign Up</Typography.Title>
                <Typography.Paragraph>
                  Join Jamtrackers and gain access to all features
                </Typography.Paragraph>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    value={username}
                    onChange={(e) => handleUsernameChange(e)}
                    prefix={<UserOutlined />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    prefix={<MailOutlined />}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  name="password-confirm"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item >
                  <ReCAPTCHA
                    onChange={handleCaptcha}
                    ref={recaptchaRef}
                    //This ref can be used to call captcha related functions in case you need.
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                </Form.Item>

                {errorMessage && (
                  <>
                    <Alert message={errorMessage} type="warning" closable />
                    <br />
                  </>
                )}
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <div style={{ textAlign: "center", fontSize: 10, maxWidth: 250 }}>
                  By registering an account with Jamtrackers you are agreeing to our Terms of Use.
                </div>
              </Col>
            </Row>
            <br />
            <Row justify="center">
              <Col>
                {isLoading ? (
                  <Spin />
                ) : (
                  <Button type="primary" htmlType="submit">
                    Sign Up
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          <br />
          <Row justify="center">
            <Col>
              <Typography.Text>Already a user?</Typography.Text>
              <Typography.Link onClick={() => navigate("/login")}>
                {" "}
                Log in{" "}
              </Typography.Link>
              <Typography.Text>instead.</Typography.Text>
            </Col>
          </Row>
        </Card>
        <br />
        <FooterLinks />
      </div >
    </div >
  );
};

export default RegisterPage;
