import React, { useContext, useState } from "react";
import { Badge, Button, Col, Modal, Row, Spin, Typography } from "antd";
import { CaretRightFilled, PauseOutlined } from "@ant-design/icons";
import { capitalizeWord, formatFromChordDb } from "../../utils";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { guitarChords, guitar } from "../../chords";
import TrackProgressSlider from "../layout/track-progress-slider";
import MusicPlayerContext from "../../contexts/music-player-context";

const RibbonWrapper = ({ chord, children }) => {
  if (chord?.marker) {
    return (
      <Badge.Ribbon text={`Start of ${chord.marker}`}>{children}</Badge.Ribbon>
    );
  }

  return children;
};

const CurrentNoteModal = ({ isOpen, onCancel, currentNote }) => {
  const { pause, play, isPlaying } = useContext(MusicPlayerContext);
  const [showAllPositions, setShowAllPositions] = useState(false);

  return (
    <Modal
      title="Live Chords"
      open={isOpen}
      closable={true}
      footer={null}
      onCancel={onCancel}
      style={{ top: 15 }}
    >
      <Row justify="center">
        <Col style={{ textAlign: "center" }}>
          {currentNote ? (
            <>
              {currentNote?.placement !== null && (
                <RibbonWrapper chord={currentNote}>

                  <Typography.Title level={3} style={{ marginTop: 0 }}>
                    {
                      `${formatFromChordDb(currentNote.note)} ${capitalizeWord(currentNote.tonality)}`
                    }
                  </Typography.Title>
                  <Row>
                    {guitarChords.chords[currentNote.note]
                      .find((n) => n.suffix === currentNote.tonality)
                      .positions.slice(0, showAllPositions ? 10 : 1)
                      .map((chord) => (
                        <Col span={showAllPositions ? 12 : 24}>
                          <Chord chord={chord} instrument={guitar} />
                        </Col>
                      ))}
                  </Row>
                  <br />
                  <Button
                    type="link"
                    onClick={() => setShowAllPositions((c) => !c)}
                  >
                    {showAllPositions ? "Hide Extra Chord Positions" : "Show More Chord Positions"}
                  </Button>
                </RibbonWrapper>
              )}
            </>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
      <br />
      <Row justify="center">
        <Col span={3}>
          {isPlaying ? (
            <Button onClick={pause} type="text">
              <PauseOutlined />
            </Button>
          ) : (
            <Button onClick={play} type="text">
              <CaretRightFilled />
            </Button>
          )}
        </Col>
        <Col span={21}>
          <TrackProgressSlider />
        </Col>
      </Row>
    </Modal>
  );
};

export default CurrentNoteModal;
