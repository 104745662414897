import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
  Empty,
  Spin,
  Tabs,
  List,
  Modal,
  notification,
  Radio,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ReadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../contexts/auth-context";
import LessonPreview from "./components/lesson-preview";
import FeedHeader from "../../components/typography/feed-header";
import { createLesson, deleteLesson, getCourse, getLesson, updateLesson } from "../../services/courses";
import { listJamtracks, getJamtrack } from "../../services/jamtracks";
import { getBlog, listBlogs } from "../../services/blogs";
import { getTool, listTools } from "../../services/tools";
import { getChordProg, listChordProgs } from "../../services/chord-progressions";
import { isValidYouTubeUrl } from "../../utils";
import VideoPlayer from "../../components/video-player/video-player";
import mediaItemTypes from "../../enums/media-item-types";
import { getPlayalong, listPlayalongs } from "../../services/yt-playalongs";

const LessonEditLoading = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 1 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 3 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 10 }} />
        </Card>
      </Col>
    </Row>
  );
};

const SectionCardTitle = ({ title, desc }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      <Typography.Text type="secondary">
        <small>{desc}</small>
      </Typography.Text>
    </div>
  );
};

const ResoursesSection = ({ lessonId, resources, onUpdateResources }) => {
  const initialSearchResults = {
    jamtracks: {
      results: [],
      hasMore: true
    },
    blogs: {
      results: [],
      hasMore: true
    },
    tools: {
      results: [],
      hasMore: true
    },
    chordProgressions: {
      results: [],
      hasMore: true
    },
    playalongs: {
      results: [],
      hasMore: true
    }
  }

  const { token } = useContext(AuthContext);
  const { jamtracks, blogs, tools, chordProgressions, playalongs } = resources;
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [resourceSearchTerm, setResearchSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchResults, setSearchResults] = useState(initialSearchResults);

  const resourceMap = {
    "Jamtrack": jamtracks,
    "Blog": blogs,
    "Tool": tools,
    "Chord Progression": chordProgressions,
    "YouTube Play Along": playalongs,
  }

  const allResources = [
    ...jamtracks.map((jt) => ({ ...jt, type: "Jamtrack" })),
    ...blogs.map((b) => ({ ...b, type: "Blog" })),
    ...tools.map((t) => ({ ...t, type: "Tool" })),
    ...chordProgressions.map((cp) => ({ ...cp, type: "Chord Progression" })),
    ...playalongs.map((p) => ({ ...p, type: "YouTube Play Along" })),
  ];

  const searchResultsEmpty = searchResults.jamtracks.results.length === 0 &&
    searchResults.blogs.results.length === 0 &&
    searchResults.chordProgressions.results.length === 0 &&
    searchResults.tools.results.length === 0 &&
    searchResults.playalongs.results.length === 0;

  const onHandleRemoveResource = (resource) => {
    const updatedList = resourceMap[resource.type].filter((x) => x.id !== resource.id);
    onUpdateResources({
      jamtracks: resource.type === "Jamtrack" ? updatedList : jamtracks,
      blogs: resource.type === "Blog" ? updatedList : blogs,
      tools: resource.type === "Tool" ? updatedList : tools,
      chordProgressions: resource.type === "Chord Progression" ? updatedList : chordProgressions,
      playalongs: resource.type === "YouTube Play Along" ? updatedList : playalongs,
    })

  };

  const onHandleAddResource = (resource) => {
    const updatedList = [...resourceMap[resource.type], resource];
    onUpdateResources({
      jamtracks: resource.type === "Jamtrack" ? updatedList : jamtracks,
      blogs: resource.type === "Blog" ? updatedList : blogs,
      tools: resource.type === "Tool" ? updatedList : tools,
      chordProgressions: resource.type === "Chord Progression" ? updatedList : chordProgressions,
      playalongs: resource.type === "YouTube Play Along" ? updatedList : playalongs,
    })
  }

  const onResetSearch = () => {
    setShowAddResourceModal(false);
    setResearchSearchTerm("");
    setSearchResults(initialSearchResults);
  }

  const getSearchResults = async () => {
    setIsLoading(true);
    try {
      const jamtrackResults = await listJamtracks(token, { search: resourceSearchTerm })
      const blogResults = await listBlogs(token, { search: resourceSearchTerm })
      const toolResults = await listTools(token, { search: resourceSearchTerm })
      const chordProgResults = await listChordProgs(token, { search: resourceSearchTerm })
      const playalongResults = await listPlayalongs(token, { search: resourceSearchTerm })

      setSearchResults((curr) => ({
        jamtracks: {
          results: jamtrackResults.results.map((x) => ({ ...x, type: "Jamtrack" })),
          hasMore: !!jamtrackResults.next,
        },
        blogs: {
          results: blogResults.results.map((x) => ({ ...x, type: "Blog" })),
          hasMore: !!blogResults.next
        },
        tools: {
          results: toolResults.results.map((x) => ({ ...x, type: "Tool" })),
          hasMore: !!toolResults.next
        },
        chordProgressions: {
          results: chordProgResults.results.map((x) => ({ ...x, type: "Chord Progression" })),
          hasMore: !!chordProgResults.next
        },
        playalongs: {
          results: playalongResults.results.map((x) => ({ ...x, type: "YouTube Play Along" })),
          hasMore: !!playalongResults.next
        }
      }))
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    const searchDebounce = setTimeout(
      async () => {
        await getSearchResults();
      },
      resourceSearchTerm ? 800 : 0,
    );

    return () => {
      clearTimeout(searchDebounce);
    };
  }, [resourceSearchTerm]);


  return (
    <Card size="small">
      <SectionCardTitle
        title="Resources"
        desc="Optional: Add resources that are relevant to this lesson."
      />
      <List size="small" dataSource={[1]}>
        {allResources.map((r) => (
          <List.Item
            key={r.id}
            style={{ marginRight: -10, marginLeft: -10 }}
            actions={[
              <Button onClick={() => onHandleRemoveResource(r)} type="text" danger>Remove</Button>
            ]}
          >
            <div>
              <div>
                <Typography.Text>
                  <b>{r.title}</b>
                </Typography.Text>
              </div>
              <Typography.Text type="secondary">
                <small>{r.type}</small>
              </Typography.Text>
            </div>
          </List.Item>
        ))}
      </List>

      <Row justify="center">
        <Col>
          <Button onClick={() => setShowAddResourceModal(true)} type="link">
            Add Resource
          </Button>
        </Col>
      </Row>
      <Modal
        open={showAddResourceModal}
        title="Add Resource"
        onCancel={() => setShowAddResourceModal(false)}
        onOk={onResetSearch}
      >
        <Input
          value={resourceSearchTerm}
          onChange={(e) => setResearchSearchTerm(e.target.value)}
          placeholder="Search Jam Tracks, Blogs, and Tools by Title"
        />
        {isLoading && (
          <Row style={{ height: 250 }} align="middle" justify="center">
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        )}
        {!isLoading && searchResultsEmpty && (
          <Row style={{ height: 250 }} align="middle" justify="center">
            <Col>
              <Empty description="No results were found that match the search term." image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          </Row>
        )
        }
        {
          !isLoading && !searchResultsEmpty && (
            <Tabs
              size="small"
              defaultActiveKey="all"
              items={
                [
                  {
                    label: 'Jam Tracks',
                    key: 'jamtracks',
                    disabled: searchResults.jamtracks.results.length === 0,
                    children: (
                      <List dataSource={searchResults.jamtracks.results}>
                        {searchResults.jamtracks.results.map((jt) => {
                          return (
                            <List.Item
                              key={jt.id}
                              actions={[
                                <>
                                  {
                                    jamtracks.filter((x) => x.id === jt.id).length > 0 ? (<Button
                                      onClick={() => onHandleRemoveResource(jt)}
                                      type="link"
                                      size="small"
                                      danger
                                    >
                                      Remove
                                    </Button>) : (<Button
                                      onClick={() => onHandleAddResource(jt)}
                                      type="link"
                                      size="small"
                                    >
                                      Add
                                    </Button>)
                                  }
                                </>
                              ]}>
                              <b>{jt.title}</b>
                            </List.Item>
                          )
                        })}
                      </List>
                    ),
                  },
                  {
                    label: 'Blogs',
                    key: 'blogs',
                    disabled: searchResults.blogs.results.length === 0,
                    children: <List dataSource={searchResults.blogs.results}>
                      {searchResults.blogs.results.map((b) => {
                        return (
                          <List.Item
                            key={b.id}
                            actions={[
                              <>
                                {
                                  blogs.filter((x) => x.id === b.id).length > 0 ? (<Button
                                    onClick={() => onHandleRemoveResource(b)}
                                    type="link"
                                    size="small"
                                    danger
                                  >
                                    Remove
                                  </Button>) : (<Button
                                    onClick={() => onHandleAddResource(b)}
                                    type="link"
                                    size="small"
                                  >
                                    Add
                                  </Button>)
                                }
                              </>
                            ]}>
                            <b>{b.title}</b>
                          </List.Item>
                        )
                      })}
                    </List>,
                  },
                  {
                    label: 'Tools',
                    key: 'tools',
                    disabled: searchResults.tools.results.length === 0,
                    children: <List dataSource={searchResults.tools.results}>
                      {searchResults.tools.results.map((t) => {
                        return (
                          <List.Item
                            key={t.id}
                            actions={[
                              <>
                                {
                                  tools.filter((x) => x.id === t.id).length > 0 ? (<Button
                                    onClick={() => onHandleRemoveResource(t)}
                                    type="link"
                                    size="small"
                                    danger
                                  >
                                    Remove
                                  </Button>) : (<Button
                                    onClick={() => onHandleAddResource(t)}
                                    type="link"
                                    size="small"
                                  >
                                    Add
                                  </Button>)
                                }
                              </>
                            ]}>
                            <b>{t.title}</b>
                          </List.Item>
                        )
                      })}
                    </List>,
                  },
                  {
                    label: 'Chord Progressions',
                    key: 'chordProgressions',
                    disabled: searchResults.chordProgressions.results.length === 0,
                    children: <List dataSource={searchResults.chordProgressions.results}>
                      {searchResults.chordProgressions.results.map((cp) => {
                        return (
                          <List.Item
                            key={cp.id}
                            actions={[
                              <>
                                {
                                  chordProgressions.filter((x) => x.id === cp.id).length > 0 ? (<Button
                                    onClick={() => onHandleRemoveResource(cp)}
                                    type="link"
                                    size="small"
                                    danger
                                  >
                                    Remove
                                  </Button>) : (<Button
                                    onClick={() => onHandleAddResource(cp)}
                                    type="link"
                                    size="small"
                                  >
                                    Add
                                  </Button>)
                                }
                              </>
                            ]}>
                            <b>{cp.title}</b>
                          </List.Item>
                        )
                      })}
                    </List>,
                  },
                  {
                    label: 'YouTube Play Alongs',
                    key: 'playalongs',
                    disabled: searchResults.playalongs.results.length === 0,
                    children: (
                      <List dataSource={searchResults.playalongs.results}>
                        {searchResults.playalongs.results.map((p) => {
                          return (
                            <List.Item
                              key={p.id}
                              actions={[
                                <>
                                  {
                                    playalongs.filter((x) => x.id === p.id).length > 0 ? (<Button
                                      onClick={() => onHandleRemoveResource(p)}
                                      type="link"
                                      size="small"
                                      danger
                                    >
                                      Remove
                                    </Button>) : (<Button
                                      onClick={() => onHandleAddResource(p)}
                                      type="link"
                                      size="small"
                                    >
                                      Add
                                    </Button>)
                                  }
                                </>
                              ]}>
                              <b>{p.title}</b>
                            </List.Item>
                          )
                        })}
                      </List>
                    ),
                  },
                ]
              }
            />
          )
        }
      </Modal >
    </Card >
  )
};

const LessonEditPage = () => {
  const navigate = useNavigate();
  const { courseId, lessonId } = useParams();
  const { token, tokenClaim } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [previewOn, setPreviewOn] = useState(false);

  const [title, setTitle] = useState("");
  const [course, setCourse] = useState(null);
  const [description, setDescription] = useState("");
  const [videoSetting, setVideoSetting] = useState("no_video");
  const [videoYoutubeUrl, setVideoYoutubeUrl] = useState("");
  const [content, setContent] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [resources, setResources] = useState({ jamtracks: [], blogs: [], tools: [], chordProgressions: [] });

  const editOrNewTitle = lessonId ? "Edit Lesson" : "New Lesson";

  const videoOptions = [
    {
      label: 'No Video',
      value: 'no_video',
    },
    {
      label: 'YouTube',
      value: 'youtube',
    },
  ];

  const loadLessonData = async () => {
    try {
      const lessonData = await getLesson(token, lessonId);

      if (
        lessonData.user.username !== tokenClaim.username
      ) {
        navigate("/");
      }

      const {
        title,
        description,
        video_setting: savedVideoSetting,
        video_youtube_url: savedYTUrl,
        content,
        is_published,
        jamtracks,
        blogs,
        tools,
        chord_progressions: chordProgressions,
        yt_playalongs: playalongs,
      } = lessonData;

      const jamtrackData = await Promise.all(jamtracks.map(async (id) => {
        return await getJamtrack(token, id)
      }));
      const blogData = await Promise.all(blogs.map(async (id) => {
        return await getBlog(token, id)
      }));
      const toolData = await Promise.all(tools.map(async (id) => {
        return await getTool(token, id)
      }));
      const chordProgData = await Promise.all(chordProgressions.map(async (id) => {
        return await getChordProg(token, id)
      }));
      const playalongData = await Promise.all(playalongs.map(async (id) => {
        return await getPlayalong(token, id)
      }));

      setTitle(title);
      setDescription(description);
      setVideoSetting(savedVideoSetting);
      setVideoYoutubeUrl(savedYTUrl);
      setContent(content);
      setIsPublished(is_published);
      setResources({
        jamtracks: jamtrackData,
        blogs: blogData,
        tools: toolData,
        chordProgressions: chordProgData,
        playalongs: playalongData,
      })
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCourseData = async () => {
    try {
      const courseData = await getCourse(token, courseId)

      if (
        courseData.user.username !== tokenClaim.username
      ) {
        navigate("/");
      }

      setCourse(courseData);
    } catch (error) {
      setHasError(true);
    }
  };

  const togglePreviewMode = () => {
    setPreviewOn(!previewOn);
  };

  const onSaveLesson = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const lessonData = {
        title,
        description,
        content,
        video_setting: videoSetting,
        video_youtube_url: videoYoutubeUrl,
        is_published: isPublished,
        course: courseId,
        order: lessonId ? lessonId.order : course.lessons.length + 1,
        jamtracks: resources.jamtracks.map((jt) => jt.id),
        blogs: resources.blogs.map((b) => b.id),
        tools: resources.tools.map((t) => t.id),
        chord_progressions: resources.chordProgressions.map((t) => t.id),
        yt_playalongs: resources.playalongs.map((p) => p.id),
      };

      if (lessonId) {
        // if (newPhotoData) lessonData.photo = newPhotoData;
        await updateLesson(token, lessonId, lessonData);
      } else {
        // lessonData.photo = newPhotoData;
        await createLesson(token, lessonData);
      }

      notification.open({
        message: "Lesson Saved Successfully",
        placement: "bottomRight",
      });
      navigate(`/courses/${courseId}/edit`);
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to save this lesson at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    try {
      await deleteLesson(token, { id: lessonId });
      notification.open({
        message: "Lesson Deleted",
        description:
          `${title} has been deleted.`,
        placement: "bottomRight",
      });
      navigate(`/courses/${courseId}/`);
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to delete this lesson at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadCourseData()
    if (lessonId) {
      loadLessonData();
    } else {
      setIsLoading(false);
    }
  }, [lessonId]);

  if (hasError) {
    return (
      <Alert
        type="error"
        message="Something went wrong..."
        description="An error occured, we are unable to load the lesson. Please try again
      later."
      />
    );
  }
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <FeedHeader icon={<ReadOutlined />} title={editOrNewTitle} />
        </Col>
        <Col>
          <Space>
            <Typography.Text type="secondary">Preview Mode</Typography.Text>
            <Switch checked={previewOn} onChange={togglePreviewMode} />
          </Space>
        </Col>
      </Row>
      {isLoading ? (
        <LessonEditLoading />
      ) : (
        <>
          {previewOn ? (
            <LessonPreview
              title={title}
              desc={description}
              videoSetting={videoSetting}
              videoYoutubeUrl={videoYoutubeUrl}
              content={content}
            />
          ) : (
            <Form
              initialValues={{
                remember: true,
                title,
                description,
                videoYoutubeUrl,
                content,
              }}
              onFinish={onSaveLesson}
            >
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Card size="small">
                    <Typography.Title level={5}>Title</Typography.Title>
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please add a title.",
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength={400}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Description"
                      desc="A brief overview of what this lesson covers."
                    />
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please add a description.",
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength={500}
                        style={{ marginBottom: 12 }}
                        showCount
                        autoSize
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Video"
                      desc="Optional: A video to accompany the lesson."
                    />
                    <div style={{ textAlign: "center" }}>
                      <Radio.Group
                        options={videoOptions}
                        onChange={(e) => setVideoSetting(e.target.value)}
                        value={videoSetting}
                        optionType="button"
                        style={{ width: "100%" }}
                      />
                    </div>
                    {videoSetting === "youtube" && (
                      <>
                        <br />
                        <Typography.Text type="secondary">
                          <small>Insert the YouTube URL below. If the video fails to appear then the link is invalid.</small>
                        </Typography.Text>
                        <Form.Item
                          name="videoYoutubeUrl"
                          rules={[
                            {
                              required: videoSetting === "youtube",
                              message: "Please add a YouTube URL.",
                            },
                          ]}
                          style={{ margin: 0 }}
                        >
                          <Input
                            value={videoYoutubeUrl}
                            onChange={(e) => setVideoYoutubeUrl(e.target.value)}
                          />
                        </Form.Item>
                        {videoYoutubeUrl && isValidYouTubeUrl(videoYoutubeUrl) && (
                          <div style={{ marginTop: 10 }}>
                            <VideoPlayer
                              src={videoYoutubeUrl}
                              mediaItem={{
                                title: title,
                                user: { username: "Work in Progress" },
                                type: mediaItemTypes.LESSON,
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <SectionCardTitle
                      title="Content"
                      desc={
                        <>
                          The content of your lesson, written in{" "}
                          <a
                            href="https://www.markdownguide.org/basic-syntax/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            markdown syntax
                          </a>
                          .
                        </>
                      }
                    />
                    <Form.Item
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Please add some content.",
                        },
                      ]}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        maxLength={100000}
                        style={{ marginBottom: 12 }}
                        showCount
                        autoSize={{ minRows: 10 }}
                      />
                    </Form.Item>
                  </Card>
                </Col>
                <Col span={24}>
                  <ResoursesSection
                    lessonId={lessonId}
                    resources={resources}
                    onUpdateResources={setResources}
                  />
                </Col>
                <Col span={24}>
                  <Card size="small">
                    <Row justify="space-between" align="middle">
                      <Col xs={24} lg={16}>
                        <SectionCardTitle
                          title="Publish"
                          desc={
                            isPublished
                              ? "This lesson will be published and available for others to read. Toggle the switch below and click save to mark it as a draft."
                              : "This lesson will be saved as a draft, only visible to you. Toggle the switch below and click save to publish it."
                          }
                        />
                      </Col>
                      <Col span="auto">
                        <Switch
                          checked={isPublished}
                          onChange={() => setIsPublished((prev) => !prev)}
                          checkedChildren="PUBLISHED"
                          unCheckedChildren="DRAFT"
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Row justify="space-between">
                    <Col>
                      {lessonId && (
                        <>
                          <Button danger onClick={() => setDeleteModal(true)} type="text">
                            Delete Lesson
                          </Button>
                          <Modal
                            open={deleteModal}
                            title="Delete Lesson"
                            onCancel={() => setDeleteModal(false)}
                            onOk={onConfirmDelete}
                            okText="Delete"
                            okType="danger"
                          >
                            <Typography.Text>Are you sure you want to delete the course <b>{title}</b>?</Typography.Text>
                          </Modal>
                        </>
                      )}
                    </Col>
                    <Col>
                      <Space>
                        <Button onClick={() => navigate("/blogs/recent")}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={
                            videoSetting === "youtube" && !isValidYouTubeUrl(videoYoutubeUrl)
                          }
                        >
                          Save
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default LessonEditPage;
