import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Typography, Card, theme, Select, Button, Space } from "antd";
import { guitarChords, guitar } from "../../chords";
import Chord from "@tombatossals/react-chords/lib/Chord";

const ChordMatches = ({ matches }) => {
  if (matches.length === 0) {
    return (
      <>
        <br />
        <Card size="small">
          <Typography.Text type="secondary">
            No matches found...
          </Typography.Text>
        </Card>
      </>
    );
  }
  return (
    <>
      <br />
      <Typography.Title level={5}>Chord Matches</Typography.Title>
      <Card>
        <Row>
          {matches.map((m) => {
            return (
              <Col sm={24} md={12}>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  {m.key} {m.suffix}
                </div>
                <Chord chord={m.positions[0]} instrument={guitar} />
              </Col>
            );
          })}
        </Row>
      </Card>
    </>
  );
};

const ChordIdentifier = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const navigate = useNavigate();

  const [firstString, setFirstString] = useState(-1);
  const [secondString, setSecondString] = useState(3);
  const [thirdString, setThirdString] = useState(2);
  const [fourthString, setFourthString] = useState(0);
  const [fifthString, setFifthString] = useState(1);
  const [sixthString, setSixthString] = useState(0);
  const [matchingChords, setMatchingChords] = useState([]);

  const findMatches = () => {
    const allChords = Object.keys(guitarChords.chords)
      .map((k) => guitarChords.chords[k])
      .flat();

    const matches = allChords
      .map((c) => {
        const filteredPositions = c.positions.filter((p) => {

          return (
            (p.baseFret - 1) + (firstString === -1 ? -1 : p.frets[0]) === firstString &&
            (p.baseFret - 1) + (secondString === -1 ? -1 : p.frets[1]) === secondString &&
            (p.baseFret - 1) + (thirdString === -1 ? -1 : p.frets[2]) === thirdString &&
            (p.baseFret - 1) + (fourthString === -1 ? -1 : p.frets[3]) === fourthString &&
            (p.baseFret - 1) + (fifthString === -1 ? -1 : p.frets[4]) === fifthString &&
            (p.baseFret - 1) + (sixthString === -1 ? -1 : p.frets[5]) === sixthString
          );
        });

        return {
          ...c,
          positions: filteredPositions,
        };
      })
      .filter((k) => k.positions.length > 0);


    setMatchingChords(matches);
  };

  const fretOptions = [-1, ...Array(24).keys()].map((f) => {
    let label = f;
    if (f === -1) {
      label = "Any";
    }

    return {
      value: f,
      label,
    };
  });

  const selectors = [
    {
      value: sixthString,
      onChange: setSixthString,
      label: "E String (High)",
    },
    {
      value: fifthString,
      onChange: setFifthString,
      label: "B String",
    },
    {
      value: fourthString,
      onChange: setFourthString,
      label: "G String",
    },
    {
      value: thirdString,
      onChange: setThirdString,
      label: "D String",
    },
    {
      value: secondString,
      onChange: setSecondString,
      label: "A String",
    },
    {
      value: firstString,
      onChange: setFirstString,
      label: "E String (Low)",
    },
  ];

  const clearSelectors = () => {
    selectors.forEach((s) => s.onChange(0));
  };

  const hasSelectedFrets =
    firstString !== 0 ||
    secondString !== 0 ||
    thirdString !== 0 ||
    fourthString !== 0 ||
    fifthString !== 0 ||
    sixthString !== 0;

  useEffect(() => {
    findMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstString,
    secondString,
    thirdString,
    fourthString,
    fifthString,
    sixthString,
  ]);

  return (
    <Row justify="center">
      <Col spa={24}>
        <Card size="small">
          <Typography.Title level={4}>Chord Identifier</Typography.Title>
          <Typography.Paragraph>
            Chord Identifier provides you with chords based on fret
            positions that you insert. Simply indicate which frets are held,
            and on which strings. We'll show you what chord it is.
          </Typography.Paragraph>
          <Row gutter={[8, 8]}>
            {selectors.map((s) => {
              return (
                <Col span={24}>
                  <Row>
                    <Col span={8}>
                      <Typography.Text>
                        <b>{s.label}</b>
                      </Typography.Text>
                    </Col>
                    <Col span={16}>
                      <Select
                        style={{
                          width: "100%",
                        }}
                        placeholder={s.label}
                        defaultValue={s.value}
                        value={s.value}
                        onChange={(value, option) => s.onChange(value)}
                        options={fretOptions}
                      />
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          {matchingChords && hasSelectedFrets && (
            <ChordMatches matches={matchingChords} />
          )}

          {hasSelectedFrets && (
            <>
              <br />
              <Row justify="center">
                <Col>
                  <Space>
                    <Button onClick={() => clearSelectors()} type="link">Reset Input</Button>
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ChordIdentifier;
