import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Button,
  Result,
  Typography,
  Space,
  Statistic,
  theme,
} from "antd";
import {
  ExclamationCircleOutlined,
  CustomerServiceOutlined,
  CommentOutlined,
  ClockCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import JamtrackListPage from "../jamtrack-list";
import BlogListPage from "../blog-list";
import { retrieveUser } from "../../services/users";
import AuthContext from "../../contexts/auth-context";
import ProfileHeroCard from "../../components/cards/profile-hero-card";
import FeedHeader from "../../components/typography/feed-header";
import { listJamtracks } from "../../services/jamtracks";
import { listChordProgs } from "../../services/chord-progressions";
import { listBlogs } from "../../services/blogs";
import { listPlayalongs } from "../../services/yt-playalongs";
import FeedContext from "../../contexts/feed-context";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import InFeedAd from "../../components/ads/in-feed-ad/in-feed-ad";
import YTPlayalongCard from "../../components/cards/yt-playalong-card/yt-playalong-card";
import ChordProgCard from "../../components/cards/chord-prog-card/chord-prog-card";
import BlogCard from "../../components/cards/blog-card/blog-card";
import JamtrackCard from "../../components/cards/jamtrack-card";
import { getAdFrequency } from "../../utils";

const QuickStatsCard = ({ user }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const joinedDate = new Date(user.date_joined).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
  });

  return (
    <Card size="small">
      <Typography.Title level={5}>Quick Stats</Typography.Title>
      <Row justify="space-between">
        <Col xs={8} md={6} lg={4}>
          <Statistic
            title="Followers"
            value={user.follower_count}
            valueStyle={{ color: colorPrimary }}
          />
        </Col>
        <Col xs={8} md={6} lg={4}>
          <Statistic
            title="Jam Tracks"
            value={user.jamtrack_count}
            valueStyle={{ color: colorPrimary }}
          />
        </Col>
        <Col xs={8} md={6} lg={4}>
          <Statistic
            title="Courses"
            value={user.course_count}
            valueStyle={{ color: colorPrimary }}
          />
        </Col>
        <Col xs={0} md={6} lg={4}>
          <Statistic
            title="Blogs"
            value={user.blog_count}
            valueStyle={{ color: colorPrimary }}
          />
        </Col>
        <Col xs={0} lg={4}>
          <Statistic
            title="Chord Progs."
            value={user.chord_prog_count}
            valueStyle={{ color: colorPrimary }}
          />
        </Col>
      </Row>
    </Card>
  );
};

const AboutCard = ({ about }) => {
  return (
    <Card size="small">
      <Typography.Title level={5}>About</Typography.Title>
      <Typography.Paragraph>{about}</Typography.Paragraph>
    </Card>
  );
};

const DonationsCard = ({ donationsUrl }) => {
  return (
    <Card size="small">
      <Typography.Title level={5}>Donations</Typography.Title>
      <Typography.Paragraph>Share a small donation to say thanks, and to encourage them to keep up the great work!</Typography.Paragraph>
      <Row justify="end">
        <Col>
          <a href={donationsUrl} target="_blank" rel="noreferrer">
            <Button type="primary" block >
              <Space>
                <DollarOutlined />
                Say Thanks
              </Space>
            </Button>
          </a>
        </Col>
      </Row>
    </Card>
  );
};

const UserProfile = () => {
  const { userId } = useParams();
  const { token } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext); // for jamtracks

  const [user, setUser] = useState({});
  const [chordProgs, setChordProgs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [playalongs, setPlayalongs] = useState([]);
  const [allResults, setAllResults] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [hasError, setHasError] = useState(false);

  const loadUserData = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const user = await retrieveUser(token, userId);
      setUser(user);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };


  const loadResourceData = async () => {
    setHasError(false);

    if (allResults.length === 0) {
      setIsLoading(true);
    } else {
      setIsPaginationLoading(true);
    }

    try {
      const {
        results: jamtrackData,
        next: hasMoreJamtracks
      } = await listJamtracks(token, {
        user: userId,
        offset: allResults.filter((r) => r.type === "jamtrack").length,
        limit: 5,
      });
      const {
        results: chordProgData,
        next: hasMoreChordProgs
      } = await listChordProgs(token, {
        user: userId,
        offset: chordProgs.length,
        limit: 5,
      });
      const {
        results: blogsData,
        next: hasMoreBlogs
      } = await listBlogs(token, {
        user: userId,
        offset: blogs.length,
        is_published: true,
        limit: 5,
      });
      const {
        results: playalongsData,
        next: hasMorePlayalongs
      } = await listPlayalongs(token, {
        user: userId,
        offset: playalongs.length,
        is_transcribed: true,
        limit: 5,
      });

      const newCombinedResults = [
        ...jamtrackData.map((jt) => ({ ...jt, type: "jamtrack" })),
        ...blogsData.map((b) => ({ ...b, type: "blog" })),
        ...chordProgData.map((cp) => ({ ...cp, type: "chordProg" })),
        ...playalongsData.map((p) => ({ ...p, type: "playalong" }))
      ].sort((a, b) => new Date(b.created) - new Date(a.created));

      const newResults = [...allResults, ...newCombinedResults];

      // keep track of counts to properly paginate
      setFeed([...feed, ...jamtrackData].filter((j) => j.user.id === userId));
      setBlogs([...blogs, ...blogsData]);
      setChordProgs([...chordProgs, ...chordProgData]);
      setPlayalongs([...playalongs, ...playalongsData]);

      setAllResults(newResults.filter((i) => i.user.id === userId));
      setCanPaginate(
        !!hasMoreJamtracks || !!hasMoreBlogs || !!hasMoreChordProgs || !!hasMorePlayalongs
      );
    } catch {
      console.log("error loading data");
      setHasError(true);
    } finally {
      setIsLoading(false);
      setIsPaginationLoading(false);
    }
  };

  useEffect(() => {
    loadUserData();
    loadResourceData();
  }, [userId]);

  useEffect(() => {
    const favoriteIds = [...feed.filter((j) => j.is_favorite)].map((j) => j.id)
    setAllResults(allResults.map((r) => {
      if (r.type !== "jamtrack") {
        return r
      } else {
        return {
          ...r,
          is_favorite: favoriteIds.includes(r.id)
        }
      }
    }))
  }, [feed]);

  if (hasError) {
    return (
      <Card size="small">
        <Result
          title="User not found."
          subTitle="Oops, we couldn't find this user. Make sure the link to their profile is correct."
          icon={<ExclamationCircleOutlined />}
        />
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Card size="small">
            <Skeleton active />
          </Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Skeleton active />
          </Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Skeleton active />
          </Card>
        </Col>
        {[1, 2, 3].map((i) => {
          return (
            <Col key={i} span={24}>
              <SkeletonJamtrackCard />
            </Col>
          );
        })}
      </Row>
    );
  }

  return (
    <>
      <Row style={{ marginBottom: 10 }} gutter={[12, 12]}>
        <Col span={24}>
          <ProfileHeroCard user={user} />
        </Col>
        <Col span={24}>
          <QuickStatsCard user={user} />
        </Col>
        {user.about && (
          <Col span={24}>
            <AboutCard about={user.about} />
          </Col>
        )}

        {user.accepts_donations && user.donations_url && (
          <Col span={24}>
            <DonationsCard donationsUrl={user.donations_url} />
          </Col>
        )}
      </Row>
      <br />
      <Typography.Title level={4}>Recent Activity</Typography.Title>
      <Row gutter={[12, 12]}>
        {allResults.map((r, i) => (
          <>
            {r.type === "jamtrack" && (
              <Col span={24}>
                <JamtrackCard jamtrack={r} />
              </Col>
            )}
            {r.type === "blog" && (
              <Col span={24}>
                <BlogCard blog={r} />
              </Col>
            )}
            {r.type === "chordProg" && (
              <Col span={24}>
                <ChordProgCard chordProg={r} onDelete={() => console.log("Gross")} />
              </Col>
            )}
            {r.type === "playalong" && (
              <Col span={24}>
                <YTPlayalongCard ytPlayalong={r} />
              </Col>
            )}
            {
              (i + 1) % getAdFrequency() == 0 && (
                <InFeedAd key={r.id} />
              )
            }
          </>
        ))}

        {isPaginationLoading && (
          <>
            {[1, 2, 3].map((i) => {
              return (
                <Col key={i} span={24}>
                  <SkeletonJamtrackCard />
                </Col>
              );
            })}
          </>
        )}
      </Row>

      {
        canPaginate && !isPaginationLoading && (
          <>
            <br />
            <Row justify="center">
              <Col>
                <Button onClick={loadResourceData} type="link">
                  Load More
                </Button>
              </Col>
            </Row>
          </>
        )
      }
    </>
  );
};

export default UserProfile;
