import React, { useContext, useEffect, useState } from "react";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import { Card, Input, Row, Col, Typography, Button, Skeleton, Spin, Alert, notification } from "antd";
import { createYTChordExtraction, getYouTubeRemainingTokens, getYTChordExtraction, listYTChordExtractions } from "../../services/tools";
import AuthContext from "../../contexts/auth-context";
import VideoPlayer from "../../components/video-player/video-player";
import mediaItemTypes from "../../enums/media-item-types";
import { useNavigate, useParams } from "react-router-dom";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { guitar, guitarChords } from "../../chords";
import { capitalizeWord, formatFromChordDb, isValidYouTubeUrl } from "../../utils";

const taskStatusMap = {
  NOT_SENT: "not_sent", // ui only,
  RECEIVED: "received",
  PROCESSING: "processing",
  COMPLETED: "completed",
  ERROR: "error",
}

const LoadingBlocks = () => {
  return (
    <>
      <Col span={24}>
        <Card size="small">
          <Skeleton.Button style={{ height: 225, width: "100%" }} active block />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Skeleton.Button active />
            </Col>
            {[1, 2, 3, 4, 5].map((i) => (
              <Col key={i} span={12}>
                <Skeleton.Button key={i} style={{ height: 200, width: "100%" }} active block />
              </Col>
            )
            )}
          </Row>
        </Card>
      </Col>
    </>
  )
};

const YTChordExtractor = ({ }) => {
  const navigate = useNavigate();
  const { token, tokenClaim } = useContext(AuthContext);
  const { taskId: paramTaskId } = useParams();

  const [isLoading, setIsLoading] = useState("false");
  const [taskId, setTaskId] = useState("")
  const [url, setUrl] = useState("");
  const [transcription, setTranscription] = useState({});
  const [status, setStatus] = useState(taskStatusMap.NOT_SENT);
  const [remainingTokens, setRemainingTokens] = useState("unlimited");
  const [hasError, setHasError] = useState(false);

  let allChords = [];
  for (const k in transcription) {
    const value = transcription[k];
    if (!allChords.some(chord => JSON.stringify(chord.data) === JSON.stringify(value))) {
      allChords.push({ data: value, count: 1, time: k })
    } else {
      allChords = allChords.map((c) => {
        if (JSON.stringify(c.data) === JSON.stringify(value)) {
          return {
            ...c,
            count: c.count + 1
          }
        }
        return c
      })
    }
  }
  const uniqueChords = allChords.filter((c) => c.count > 1).sort((a, b) => a.time - b.time);

  const loadParamTaskIdData = async () => {
    // TODO setisloading
    try {
      const {
        url: taskUrl,
        transcription: taskTranscription,
        status: taskStatus
      } = await getYTChordExtraction(token, paramTaskId);
      setUrl(taskUrl);
      setTranscription(taskTranscription);
      setStatus(taskStatus);
    } catch {
      setHasError(true);
    }
  };

  const loadRemainingTokens = async () => {
    setHasError(false);
    try {
      const remaining = await getYouTubeRemainingTokens(token);
      setRemainingTokens(remaining);
    } catch {
      setHasError(true);
    }
  };

  const resetState = () => {
    setUrl("");
    setTranscription("");
    setStatus(taskStatusMap.NOT_SENT);
    window.history.pushState
      (
        null,
        "",
        "/tools/yt-chord-extractor/"
      );
  };

  const onClickExtract = async () => {
    setHasError(false);
    if (!isValidYouTubeUrl(url)) {
      notification.open({
        message: "Please enter a valid YouTube URL",
        description: "The URL you entered does not appear to be a valid YouTube URL. Please correct it and try again.",
        placement: "bottomRight",
      })
      return
    }
    try {
      const taskData = {
        url,
      }
      const { results } = await listYTChordExtractions(token, { url })
      if (results.length > 0) {
        navigate(`/tools/yt-chord-extractor/${results[0].id}`)
      } else {
        const { status, id } = await createYTChordExtraction(token, taskData);
        window.history.pushState
          (
            null,
            "",
            `/tools/yt-chord-extractor/${id}`
          );
        setStatus(status);
        setTaskId(id);
        loadRemainingTokens();
      }
    } catch {
      setHasError(true);
    }
  };

  const checkTaskStatus = async () => {
    try {
      const { status, transcription } = await getYTChordExtraction(token, taskId)
      setStatus(status);
      if (status === taskStatusMap.COMPLETED) {
        setTranscription(transcription);
      }
    } catch {
      setHasError(true);
    }
  }

  useEffect(() => {
    let intervalId;

    if (status == taskStatusMap.RECEIVED) {
      intervalId = setInterval(() => {
        checkTaskStatus();
      }, 5000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [status]);

  useEffect(() => {
    loadRemainingTokens();
    if (paramTaskId) {
      loadParamTaskIdData();
    }
  }, [paramTaskId])

  return (
    <Card size="small">
      <Typography.Title level={4}>YouTube Chord Extractor</Typography.Title>
      <Row gutter={[12, 12]}>
        {status === taskStatusMap.NOT_SENT && (
          <>
            {remainingTokens === 0 &&
              <Col span={24}>
                <Alert type="error" message="You don't have any more credits. Please wait 24 hours before trying again."></Alert>
              </Col>
            }
            {!token || !tokenClaim.is_verified ? (
              <Col span={24}>
                <Alert message="You must be logged in and have verified your email in order to use this tool."></Alert>
              </Col>
            ) : (
              <Col span={24}>
                <Card size="small">
                  <Row gutter={[12, 12]}>
                    <Col flex={"auto"}>
                      <Input
                        placeholder="Paste a YouTube URL"
                        size="large"
                        value={url}
                        type="url"
                        disabled={status !== taskStatusMap.NOT_SENT || remainingTokens === 0}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={onClickExtract}
                        size="large"
                        type="primary"
                        disabled={!url || status !== taskStatusMap.NOT_SENT || remainingTokens === 0}>
                        Extract Chords
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
            <Col span={24}>
              <Card size="small">
                <Typography.Text>Insert the URL of a YouTube video and we'll extract the chords from it. You'll be able to follow chord changes in real time and view chord charts, making it incredibly easy to learn a song!</Typography.Text>
              </Card>
            </Col>
          </>
        )}
        {status === taskStatusMap.RECEIVED && (
          <>
            <Col span={24}>
              <Card size="small">
                <Row justify="space-between" align="middle">
                  <Col>
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>Extracting Chords...</Typography.Title>
                  </Col>
                  <Col>
                    <Spin indicator={<LoadingOutlined />} size="large" />
                  </Col>
                </Row>
              </Card>
            </Col>
            <LoadingBlocks />
          </>
        )}
        {status === taskStatusMap.COMPLETED && !!transcription && (
          <>
            <Col span={24}>
              <Card size="small">
                <Row justify="space-between" align="middle">
                  <Col span={24}>
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>Chords Extracted Successfully!</Typography.Title>
                    {remainingTokens !== "unlimited" & remainingTokens > 0 && (
                      <Typography.Paragraph>You are still able to transcribe {remainingTokens} videos.</Typography.Paragraph>
                    )}
                    {remainingTokens === 0 && (
                      <Typography.Paragraph>You have used all of your YouTube extraction credits. Please wait 24 hours before trying again.</Typography.Paragraph>
                    )}
                  </Col>
                  {remainingTokens !== 0 &&
                    <Col span={24}>
                      <Button block onClick={resetState}>Extract Chords from Another Video</Button>
                    </Col>
                  }
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card size="small">
                <VideoPlayer
                  src={url}
                  mediaItem={{
                    id: taskId || paramTaskId,
                    transcription: transcription,
                    is_transcribed: !!transcription,
                    type: mediaItemTypes.YT_CHORD_EXTRACTOR,
                    title: "YouTube Chord Extractor",
                    user: { username: url, photo: null },
                  }} />
              </Card>
            </Col>
            <Col span={24}>
              <Card size="small">
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <Typography.Title level={5}>Frequent Chords</Typography.Title>
                  </Col>
                  {uniqueChords.map((uq) => (
                    <Col key={`${uq.data.note}-${uq.data.tonality}`} span={12}>
                      {guitarChords.chords[uq.data.note]
                        .find((n) => n.suffix === uq.data.tonality)
                        .positions.slice(0, 1)
                        .map((chord) => (
                          <div style={{ textAlign: "center" }} key={`${uq.data.note}-${uq.data.tonality}`}>
                            <Chord chord={chord} instrument={guitar} />
                            <Typography.Text>
                              {formatFromChordDb(uq.data.note)} {capitalizeWord(uq.data.tonality)}
                            </Typography.Text>
                          </div>
                        ))}
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </>
        )}
        {status === taskStatusMap.ERROR && (
          <>
            <Col span={24}>
              <Alert
                type="error"
                message="An Error Occurred"
                description="We were unable to extract chords from this video, or it is longer than the 5 minute maximum video length."
              />
            </Col>
            <Col span={24}>
              <Button onClick={resetState} block>Extract Chords from Another Video</Button>
            </Col>
          </>
        )}
      </Row>
    </Card>
  )
};

export default YTChordExtractor;