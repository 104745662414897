import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Typography, Card, theme, Select, Button, Space, Input, Form } from "antd";
import { PlusOutlined, DeleteOutlined, PushpinOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { guitarChords, guitar } from "../../chords";
import { default as ReactChord } from "@tombatossals/react-chords/lib/Chord";
import { getInitialParamsDict } from "../../utils";
import { Key, Note, Chord } from "tonal";
import { tonalMap } from "../../enums/tonal";
import { keysMap, tonalityMap } from "../../enums/keys";
import { tonalToReactChordMap } from "../../enums/tonalToReactChords";
import { createChordProg } from "../../services/chord-progressions";
import AuthContext from "../../contexts/auth-context";

const keyOptions = Object.keys(keysMap).map((k) => {
  return {
    value: k,
    label: keysMap[k],
  };
});

const tonalityOptions = Object.keys(tonalityMap).map((t) => {
  return {
    value: t,
    label: tonalityMap[t],
  };
});

const numChordsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
  return {
    value: i,
    label: i,
  }
})

const SaveCard = ({ chords, note, tonality }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescriptions] = useState('');

  const onSave = async () => {
    try {
      setIsLoading(true);
      await createChordProg(token, { title, description, key: note, tonality, chords })
      navigate("/chord-progressions")
    } catch (e) {
      console.log(e);
      //TODO: show error
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Card size="small" style={{ width: "100%" }}>
      <Typography.Title level={5}>
        Save Chord Progression
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        Save and publish this chord progression so that it is available to your followers.
      </Typography.Paragraph>
      <Form.Item>
        <Input
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Input.TextArea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescriptions(e.target.value)}
          maxLength={500}
          showCount
        />
      </Form.Item>
      <div style={{ textAlign: "right", marginTop: 12 }}>
        <Button onClick={onSave} disabled={!title} type="primary">
          Save
        </Button>
      </div>
    </Card>
  )
};

const ChordProgressionGenerator = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const {
    tokenClaim: {
      is_staff: isStaff
    }
  } = useContext(AuthContext);

  const location = useLocation();
  const initialParams = getInitialParamsDict(location.search); // TODO

  const [tonic, setTonic] = useState(null);
  const [tonality, setTonality] = useState(null);
  const [numChords, setNumChords] = useState(null);
  const [chords, setChords] = useState([])

  const jamtrackKey = tonality === "minor" ? Key.minorKey(tonalMap[tonic]).natural : Key.majorKey(tonalMap[tonic])
  const chordOptions = jamtrackKey.chords;

  const getChordPlacement = (note, tonality) => {
    const positions = guitarChords.chords[tonalToReactChordMap[note]]
      .find((n) => n.suffix === tonality)
      .positions;


    const randomIndex = Math.floor(Math.random() * positions.length);
    return positions[randomIndex];
  }

  const constructComplexChord = (basicChord) => {
    const { tonic: root, aliases } = Chord.get(basicChord);
    const simplifiedTonic = Note.simplify(root)
    const tonality = aliases[0]

    const randomPlacement = getChordPlacement(simplifiedTonic, tonality);
    return {
      tonic: simplifiedTonic,
      tonality: tonality,
      chord: randomPlacement,
      isPinned: false,
    };
  }

  const getChord = () => {
    const randomIndex = Math.floor(Math.random() * chordOptions.length);
    return constructComplexChord(chordOptions[randomIndex]);
  };

  const generateChordProgression = () => {
    let newChords = [];
    for (let i = 0; i < numChords; i++) {
      const newSelectedChords = [...newChords, getChord()];
      newChords = newSelectedChords;
    }
    setChords(newChords);
  }

  const refreshChords = () => {
    const newChords = chords.map((c) => {
      if (c.isPinned) {
        return c
      }
      return getChord();
    })

    setChords(newChords);
  };

  const togglePinChord = (chord, index) => {
    const newChords = chords.map((c, i) => {
      if (i === index) {
        return (
          {
            ...c,
            isPinned: !c.isPinned,
          }
        )
      };

      return c
    });

    setChords(newChords);
  }

  const addChord = () => {
    const newChords = [...chords, getChord()];
    setChords(newChords);
    setNumChords((old) => old + 1);
  };

  const deleteChord = (index) => {
    setChords(chords.filter((c, i) => i !== index));
    setNumChords((old) => old - 1);
  };

  const moveChord = (chord, index, newIndex) => {
    const filteredChords = chords.filter((_, i) => i !== index);

    const newChords = [
      ...filteredChords.slice(0, newIndex),
      chord,
      ...filteredChords.slice(newIndex)
    ];

    setChords(newChords);
  };

  const handleClear = () => {
    setTonic(null);
    setTonality(null);
    setNumChords(null);
    setChords([]);
    window.history.replaceState(null, "", location.pathname);
  };

  return (

    <Row justify="center">
      <Col span={24}>
        <Card size="small">
          <Typography.Title level={4}>Chord Progression Generator</Typography.Title>
          <Typography.Paragraph>
            The Chord Progression Generator allows you to start jamming quickly by providing you with a randomly generated series of chords for a given key.
          </Typography.Paragraph>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <Select
                value={tonic}
                onChange={(e) => setTonic(e)}
                options={keyOptions}
                placeholder="Note"
                dropdownMatchSelectWidth={false}
                disabled={chords.length > 0}
              />
            </Col>
            <Col>
              <Select
                value={tonality}
                onChange={(e) => setTonality(e)}
                options={tonalityOptions}
                placeholder="Tonality"
                disabled={chords.length > 0}
              />
            </Col>
            <Col>
              <Select
                value={numChords}
                onChange={(e) => setNumChords(e)}
                options={numChordsOptions}
                placeholder="# of Chords"
                disabled={chords.length > 0}
              />
            </Col>
            <Col>
              {chords.length === 0 ? (
                <Button
                  onClick={generateChordProgression}
                  type="primary"
                  disabled={!tonic || !tonality || !numChords}
                >
                  Generate
                </Button>
              ) : (
                <Button
                  onClick={refreshChords}
                  type="primary"
                  disabled={chords.every((c) => c.isPinned)}
                >
                  Refresh
                </Button>
              )}
            </Col>
          </Row>
          {(chords.length > 0) && (
            <Row justify="center" style={{ marginTop: 10 }}>
              <Col>
                <Button type="link" onClick={handleClear}>Reset Chords</Button>
              </Col>
            </Row>
          )}
          {chords.length > 0 && (
            <>
              <br />
              <Row gutter={[12, 12]} align="middle">
                {chords.map((chord, i) => {
                  return (
                    <Col span={12} key={i}>
                      <Card size="small">
                        <Row justify="space-between">
                          <Col>
                            <Button
                              size="small"
                              shape="circle"
                              onClick={() => togglePinChord(chord, i)}
                              type={chord.isPinned ? "primary" : "default"}
                            >
                              <PushpinOutlined />
                            </Button>
                          </Col>
                          <Col>
                            <Space>
                              {i > 0 && (
                                <Button
                                  size="small"
                                  shape="circle"
                                  onClick={() => moveChord(chord, i, i - 1)}
                                >
                                  <ArrowLeftOutlined />
                                </Button>
                              )}
                              {i < numChords - 1 && (
                                <Button
                                  size="small"
                                  shape="circle"
                                  onClick={() => moveChord(chord, i, i + 1)}
                                >
                                  <ArrowRightOutlined />
                                </Button>
                              )}
                            </Space>
                          </Col>
                          <Col>
                            <Button
                              size="small"
                              shape="circle"
                              danger
                              onClick={() => deleteChord(i)}
                              disabled={chords.length === 1}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Col>
                        </Row>
                        <ReactChord chord={chord.chord} instrument={guitar} />
                        <div style={{ textAlign: "center" }}>
                          <div>
                            <Typography.Text type="secondary">
                              Chord {i + 1}
                            </Typography.Text>
                          </div>
                          <b>{chord.tonic} {chord.tonality}</b>
                        </div>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <Button size="large" onClick={addChord}>
                  <Space>
                    <PlusOutlined />
                    Add Chord
                  </Space>
                </Button>
              </div>
              {isStaff && (
                <>
                  <br />
                  <div>
                    <SaveCard
                      chords={chords}
                      note={tonic}
                      tonality={tonality}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default ChordProgressionGenerator;
