import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CustomLayout from "./components/layout/custom-layout/custom-layout";
import JamtrackListPage from "./pages/jamtrack-list";
import FaqPage from "./pages/faqs";
import CookiePolicy from "./pages/cookie-policy/index.js";
import LoginPage from "./pages/login";
import AuthContext from "./contexts/auth-context";
import Transcribe from "./pages/transcribe";
import NotificationListPage from "./pages/notification-list";
import ChordExplorer from "./pages/tool-chord-explorer";
import ChordIdentifier from "./pages/tools-chord-identifier";
import AboutPage from "./pages/about";
import TermsAndConditions from "./pages/terms-and-conditions";
import ChordOfTheDayList from "./pages/tools-chord-of-the-day-list";
import RegisterPage from "./pages/register";
import PasswordForgot from "./pages/password-forgot";
import PasswordReset from "./pages/password-reset";
import EditProfile from "./pages/edit-profile";
import VideoCaptureModal from "./pages/youtube-capture";
import ReactGA from "react-ga4";
import ogImage from "./media/images/og_image.png";
import UserProfile from "./pages/user-profile";
import CreatorApplicationPage from "./pages/creator-application/creator-application";
import ToolsList from "./pages/tools-list/tools-list";
import JamtrackDetailPage from "./pages/jamtrack-detail";
import GenreListPage from "./pages/genre-list";
import HomePage from "./pages/home";
import BlogListPage from "./pages/blog-list/index.js";
import BlogDetailPage from "./pages/blog-detail/index.js";
import BlogEditPage from "./pages/blog-edit/index.js";
import NavigateHandler from "./utils/navigate-handler.js";
import ErrorPage from "./pages/error/index.js";
import CourseListPage from "./pages/course-list/index.js";
import CourseDetailPage from "./pages/course-detail/index.js";
import LessonDetailPage from "./pages/lesson-detail/index.js";
import CourseEditPage from "./pages/course-edit/index.js";
import LessonEditPage from "./pages/lesson-edit/index.js";
import SearchPage from "./pages/search/index.js";
import AccountSettingsPage from "./pages/account-settings/index.js";
import ChordProgressionGenerator from "./pages/tools-chord-prog-generator/index.js";
import ChordProgListPage from "./pages/chord-prog-list/index.js";
import PrivacyPolicy from "./pages/privacy-policy/index.js";
import ChordProgEditPage from "./pages/chord-prog-edit/index.js";
import ScaleExporer from "./pages/tools-scale-explorer/index.js";
import YTPlayalongListPage from "./pages/yt-playalong-list/index.js";
import YTPlayalongDetailPage from "./pages/yt-playalong-detail/index.js";
import YTPlayalongEditPage from "./pages/yt-playalong-edit/index.js";
import VerifyPage from "./pages/verify-account/index.js";
import "./main.scss";
import YTChordExtractor from "./pages/tools-yt-chord-extractor/index.js";

const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
ReactGA.initialize(gaMeasurementId);

function App() {
  const {
    isLoggedIn,
    tokenClaim: { is_staff: isStaff, is_creator: isCreator, is_verified: isVerified },
  } = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Jamtrackers - Discover and share transcribed backing tracks"
        />
        <meta property="og:image" content={ogImage} />
        <meta name="twitter:card" content={ogImage} />
      </Helmet>
      <BrowserRouter>
        <NavigateHandler />
        <CustomLayout>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route
              exact
              path="/login"
              element={!isLoggedIn ? <LoginPage /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/register"
              element={!isLoggedIn ? <RegisterPage /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/verification"
              element={!isVerified && isLoggedIn ? <VerifyPage /> : <Navigate to="/register" />}
            />

            {/* JAMTRACKS */}
            <Route
              exact
              path="/jamtracks"
              element={<Navigate to="/jamtracks/lead" />}
            />
            <Route
              exact
              path="/jamtracks/lead"
              element={
                <JamtrackListPage
                  initialParams={{ no_drums: false, no_bass: false }}
                />
              }
            />
            <Route
              exact
              path="/jamtracks/bass"
              element={
                <JamtrackListPage
                  initialParams={{ no_bass: true, no_drums: false }}
                />
              }
            />
            <Route
              exact
              path="/jamtracks/drums"
              element={
                <JamtrackListPage
                  initialParams={{ no_drums: true, no_bass: false }}
                />
              }
            />
            <Route
              exact
              path="/jamtracks/recent"
              element={
                <JamtrackListPage
                  initialParams={{}}
                />
              }
            />
            <Route
              exact
              path="/jamtracks/featured"
              element={
                <JamtrackListPage
                  feedTitle="Featured"
                  initialParams={{ is_featured: true }}
                />
              }
            />
            <Route
              exact
              path="/jamtracks/favorites"
              element={
                !isLoggedIn ? (
                  <Navigate to="/login" />
                ) : (
                  <JamtrackListPage
                    feedTitle="My Favorites"
                    initialParams={{ is_favorite: 1 }}
                  />
                )
              }
            />
            <Route
              exact
              path="/jamtracks/following"
              element={
                !isLoggedIn ? (
                  <LoginPage />
                ) : (
                  <JamtrackListPage
                    feedTitle="Following"
                    initialParams={{ in_following_feed: 1 }}
                  />
                )
              }
            />
            <Route
              exact
              path="/jamtracks/genres"
              element={<GenreListPage />}
            />
            <Route
              exact
              path="/jamtracks/:jamtrackId"
              element={<JamtrackDetailPage />}
            />
            <Route
              exact
              path="/transcribe/:jamtrackId"
              element={!isLoggedIn ? <LoginPage /> : <Transcribe />}
            />

            {/* YT PLAYALONGS */}
            <Route
              exact
              path="/yt-playalongs"
              element={<Navigate to="/yt-playalongs/recent" />}
            />
            <Route
              exact
              path="/yt-playalongs/recent"
              element={
                <YTPlayalongListPage
                  feedTitle="Recently Added"
                  initialParams={{
                    is_transcribed: true,
                  }}
                />
              }
            />
            <Route
              exact
              path="/yt-playalongs/featured"
              element={
                <YTPlayalongListPage
                  feedTitle="Featured"
                  initialParams={{
                    is_featured: true,
                    is_transcribed: true,
                  }}
                />
              }
            />
            <Route
              exact
              path="/yt-playalongs/favorites"
              element={
                !isLoggedIn ? (
                  <Navigate to="/login" />
                ) : (
                  <YTPlayalongListPage
                    feedTitle="My Favorites"
                    initialParams={{
                      is_favorite: 1,
                      is_transcribed: true,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/yt-playalongs/following"
              element={
                !isLoggedIn ? (
                  <LoginPage />
                ) : (
                  <YTPlayalongListPage
                    feedTitle="Following"
                    initialParams={{
                      in_following_feed: 1,
                      is_transcribed: true,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/yt-playalongs/:playalongId"
              element={<YTPlayalongDetailPage />}
            />
            <Route
              exact
              path="/yt-playalongs/:playalongId/edit"
              element={
                isCreator ? <YTPlayalongEditPage /> : <Navigate to="/yt-playalongs" />
              }
            />
            <Route
              exact
              path="/yt-playalongs/new"
              element={
                isCreator ? <YTPlayalongEditPage /> : <Navigate to="/yt-playalongs" />
              }
            />

            {/* BLOGS */}
            <Route
              exact
              path="/blogs"
              element={<Navigate to="/blogs/recent" />}
            />
            <Route
              exact
              path="/blogs/recent"
              element={
                <BlogListPage
                  feedTitle="Recently Added"
                  initialParams={{ is_published: true }}
                />
              }
            />
            <Route
              exact
              path="/blogs/featured"
              element={
                <BlogListPage
                  feedTitle="Featured"
                  initialParams={{ is_featured: true, is_published: true }}
                />
              }
            />
            <Route
              exact
              path="/blogs/favorites"
              element={
                !isLoggedIn ? (
                  <Navigate to="/login" />
                ) : (
                  <BlogListPage
                    feedTitle="My Favorites"
                    initialParams={{ is_favorite: 1, is_published: true }}
                  />
                )
              }
            />
            <Route
              exact
              path="/blogs/following"
              element={
                !isLoggedIn ? (
                  <LoginPage />
                ) : (
                  <BlogListPage
                    feedTitle="Following"
                    initialParams={{
                      in_following_feed: 1,
                      is_published: true,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/blogs/drafts"
              element={
                !isCreator ? (
                  <Navigate to="/blogs" />
                ) : (
                  <BlogListPage
                    feedTitle="My Drafts"
                    initialParams={{
                      is_published: false,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/blogs/:blogId"
              element={<BlogDetailPage />}
            />
            <Route
              exact
              path="/blogs/:blogId/edit"
              element={
                isCreator ? <BlogEditPage /> : <Navigate to="/blogs" />
              }
            />
            <Route
              exact
              path="/blogs/new"
              element={
                isCreator ? <BlogEditPage /> : <Navigate to="/blogs" />
              }
            />

            {/* COURSES */}
            <Route
              exact
              path="/courses"
              element={<Navigate to="/courses/recent" />}
            />
            <Route
              exact
              feedTitle="Recently Added"
              path="/courses/recent"
              element={<CourseListPage initialParams={{ is_published: true }} />}
            />
            <Route
              exact
              path="/courses/featured"
              element={
                <CourseListPage
                  feedTitle="Featured"
                  initialParams={{ is_featured: true, is_published: true }}
                />
              }
            />
            <Route
              exact
              path="/courses/favorites"
              element={
                !isLoggedIn ? (
                  <Navigate to="/login" />
                ) : (
                  <CourseListPage
                    feedTitle="My Favorites"
                    initialParams={{ is_favorite: 1, is_published: true }}
                  />
                )
              }
            />
            <Route
              exact
              path="/courses/following"
              element={
                !isLoggedIn ? (
                  <LoginPage />
                ) : (
                  <CourseListPage
                    feedTitle="Following"
                    initialParams={{
                      in_following_feed: 1,
                      is_published: true,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/courses/:courseId"
              element={<CourseDetailPage />}
            />
            <Route
              exact
              path="/courses/:courseId/edit"
              element={
                isCreator ? <CourseEditPage /> : <Navigate to="/blogs" />
              }
            />
            <Route
              exact
              path="/courses/new"
              element={
                isCreator ? <CourseEditPage /> : <Navigate to="/blogs" />
              }
            />
            <Route
              exact
              path="/courses/drafts"
              element={
                !isCreator ? (
                  <Navigate to="/courses" />
                ) : (
                  <CourseListPage
                    feedTitle="My Drafts"
                    initialParams={{
                      is_published: false,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/courses/:courseId/lessons/:lessonId"
              element={<LessonDetailPage />}
            />
            <Route
              exact
              path="/courses/:courseId/lessons/:lessonId/edit"
              element={
                isCreator ? <LessonEditPage /> : <Navigate to="/courses" />
              }
            />
            <Route
              exact
              path="/courses/:courseId/lessons/new"
              element={
                isCreator ? <LessonEditPage /> : <Navigate to="/courses" />
              }
            />
            {/* CHORD PROGRESSIONS */}
            <Route
              exact
              path="/chord-progressions"
              element={<Navigate to="/chord-progressions/recent" />}
            />
            <Route
              exact
              feedTitle="Recently Added"
              path="/chord-progressions/recent"
              element={<ChordProgListPage />}
            />
            <Route
              exact
              path="/chord-progressions/featured"
              element={
                <ChordProgListPage
                  feedTitle="Featured"
                  initialParams={{ is_featured: true, is_published: true }}
                />
              }
            />
            <Route
              exact
              path="/chord-progressions/favorites"
              element={
                !isLoggedIn ? (
                  <Navigate to="/login" />
                ) : (
                  <ChordProgListPage
                    feedTitle="My Favorites"
                    initialParams={{ is_favorite: 1, is_published: true }}
                  />
                )
              }
            />
            <Route
              exact
              path="/chord-progressions/following"
              element={
                !isLoggedIn ? (
                  <LoginPage />
                ) : (
                  <ChordProgListPage
                    feedTitle="Following"
                    initialParams={{
                      in_following_feed: 1,
                      is_published: true,
                    }}
                  />
                )
              }
            />
            <Route
              exact
              path="/chord-progressions/:chordProgId/edit"
              element={
                isCreator ? <ChordProgEditPage /> : <Navigate to="/chord-progressions" />
              }
            />
            <Route
              exact
              path="/chord-progressions/new"
              element={
                isCreator ? <ChordProgEditPage /> : <Navigate to="/chord-progressions" />
              }
            />

            {/* TOOLS */}
            <Route exact path="/tools" element={<ToolsList />} />
            <Route
              exact
              path="/tools/chord-explorer"
              element={<ChordExplorer />}
            />
            <Route
              exact
              path="/tools/chord-identifier"
              element={<ChordIdentifier />}
            />
            <Route
              exact
              path="/tools/chord-of-the-day"
              element={<ChordOfTheDayList />}
            />
            <Route
              exact
              path="/tools/chord-progression-generator"
              element={<ChordProgressionGenerator />}
            />
            <Route
              exact
              path="/tools/scale-explorer"
              element={<ScaleExporer />}
            />
            <Route
              exact
              path="/tools/yt-chord-extractor"
              element={<YTChordExtractor />}
            />
            <Route
              exact
              path="/tools/yt-chord-extractor/:taskId"
              element={<YTChordExtractor />}
            />

            {/* OTHER PATHS */}
            <Route exact path="/home" element={<HomePage />} />
            <Route exact path="/search" element={<SearchPage />} />
            <Route
              exact
              path="/musicians/:userId"
              element={<UserProfile />}
            />
            <Route
              exact
              path="/notifications"
              element={!isLoggedIn ? <LoginPage /> : <NotificationListPage />}
            />
            <Route
              exact
              path="/edit-profile"
              element={!isCreator ? <LoginPage /> : <EditProfile />}
            />
            <Route
              exact
              path="/account-settings"
              element={!isLoggedIn ? <LoginPage /> : <AccountSettingsPage />}
            />
            <Route
              exact
              path="/forgot-password"
              element={<PasswordForgot />}
            />
            <Route
              exact
              path="/reset-password/:passwordResetId"
              element={<PasswordReset />}
            />
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />
            <Route
              exact
              path="/creator-application"
              element={<CreatorApplicationPage />}
            />
            <Route exact path="/faqs" element={<FaqPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path="/staff/youtube-capture"
              element={
                isLoggedIn && isStaff ? (
                  <VideoCaptureModal />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            {/* Error and 404 */}
            <Route
              exact
              path="/error"
              element={<ErrorPage />}
            />
          </Routes>
        </CustomLayout>
      </BrowserRouter>
    </>
  );
}

export default App;
