import React, { useContext, useState } from "react";
import { Card, Row, Col, Typography, Space, Avatar, Tag, Dropdown, Button, notification, Modal } from "antd";
import {
  KeyOutlined,
  NumberOutlined,
  DeleteOutlined,
  // ShareAltOutlined,
  MoreOutlined,
  HeartFilled,
  HeartOutlined,
  EditOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { default as ReactChord } from "@tombatossals/react-chords/lib/Chord";
import { guitar } from "../../../chords";
import { useNavigate } from "react-router-dom";
import UserProfilePopover from "../../user-profile-popover/user-profile-popover";
import { getTimeAgo } from "../../../utils";
import styles from "./chord-prog-card.module.scss"
import { keysMap, tonalityMap } from "../../../enums/keys";
import AuthContext from "../../../contexts/auth-context";
import { toggleChordProgFavorite } from "../../../services/chord-progressions";

const ActionButtons = ({ isOwner, chordProg, onDelete }) => {
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(hostname + `/blogs/${chordProg.id}`);

    notification.open({
      message: "Copied to Clipboard",
      description:
        "A direct link to this blog has been copied to your clipboard.",
      placement: "bottomRight",
    });
  };

  const items = [
    // {
    //   key: "share",
    //   label: "Share",
    //   icon: <ShareAltOutlined />,
    //   onClick: handleClickShare,
    // },
  ];

  if (isOwner) {
    items.push(
      {
        key: "delete",
        label: "Delete",
        danger: true,
        icon: <DeleteOutlined />,
        onClick: () => setShowDeleteModal(true),
      },
      {
        key: "edit",
        label: "Edit",
        icon: <EditOutlined />,
        onClick: () => navigate(`/chord-progressions/${chordProg.id}/edit`),
      },
    );
  }

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          items,
        }}
      >
        <Button icon={<MoreOutlined />} type="text" size="medium" />
      </Dropdown>
      <Modal
        title="Delete Chord Progression"
        open={showDeleteModal}
        closable={false}
        onCancel={() => setShowDeleteModal(false)}
        onOk={() => onDelete(chordProg)}
        okText="Delete"
        okType="danger"
      >
        Are you sure you want to delete {chordProg.title}? It will not be
        possible to recover it.
      </Modal >
    </>
  );
};

const ChordProgCard = ({ chordProg, onDelete }) => {
  const navigate = useNavigate();
  const { isLoggedIn, token, tokenClaim } = useContext(AuthContext);
  const [isFavorite, setIsFavorite] = useState(chordProg.is_favorite);

  const isOwner = tokenClaim.username === chordProg.user.username;

  const getActionButtons = () => {
    const actions = [];
    const favoriteIcon = isFavorite ? <HeartFilled /> : <HeartOutlined />;

    if (isLoggedIn) {
      actions.push(
        <div onClick={handleFavoriteClick}>
          {favoriteIcon}
        </div>
      );
    }

    return actions;
  };

  const handleFavoriteClick = async () => {
    try {
      await toggleChordProgFavorite(token, chordProg);

      setIsFavorite((fav) => !fav);

      notification.open({
        message: chordProg.is_favorite ? "Chord Progression Unfavorited" : "Chord Progression Favorited",
        description: `This chord progression has been ${chordProg.is_favorite ? "removed" : "added"
          } to your favorites list.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card size="small" actions={getActionButtons()}>
      <Row justify="space-between">
        <Col span={20}>
          <Space align="start" size="middle">
            <UserProfilePopover
              user={chordProg.user}
              isFollowing={chordProg.in_following_feed}
            >
              <Avatar
                shape="square"
                size="large"
                src={chordProg.user.photo}
              >
                {chordProg.user.username[0]}
              </Avatar>
            </UserProfilePopover>
            <Space direction="vertical" size="middle">
              <div>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  <Space className={styles["blog-title"]}>{chordProg.title}</Space>
                </Typography.Title>
                <Space
                  split={<Typography.Text type="secondary">•</Typography.Text>}
                >
                  <Typography.Link type="secondary" onClick={() => navigate("/chord-progressions/recent")}>
                    <Space>
                      <small>
                        <AppstoreOutlined />
                      </small>
                      <small>
                        Chord Progression
                      </small>
                    </Space>
                  </Typography.Link>
                </Space>
              </div>
            </Space>
          </Space>
        </Col>
        <Col>
          <div style={{ textAlign: "right" }}>
            <ActionButtons
              chordProg={chordProg}
              isOwner={isOwner}
              onDelete={onDelete}
            />
          </div>
        </Col>
      </Row>
      {chordProg.description && (
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <Typography.Text>
              {chordProg.description}
            </Typography.Text>
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: 10, marginBottom: 10 }}>
        <Col span={34}>
          <Space wrap>
            <Tag bordered={false} icon={<KeyOutlined />}>
              {`${keysMap[chordProg.key]} ${tonalityMap[chordProg.tonality]}`}
            </Tag>
            <Tag bordered={false} icon={<NumberOutlined />}>
              {`${chordProg.chords.length} Chord${chordProg.chords.length > 1 ? "s" : ""}`}
            </Tag>
          </Space>
        </Col>
      </Row>
      <Row>
        {chordProg.chords.map((chord, i) => (
          <Col xs={12} md={8} key={i}>
            <ReactChord chord={chord.chord} instrument={guitar} />
            <div style={{ textAlign: "center" }}>
              <div>
                <Typography.Text type="secondary">
                  Chord {i + 1}
                </Typography.Text>
              </div>
              <b>{chord.tonic} {chord.tonality}</b>
            </div>
          </Col>
        ))}
      </Row>
    </Card>
  )
};

export default ChordProgCard;