import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Typography, Card, theme, Select, Button, Space } from "antd";
import { capitalizeWord, formatFromChordDb, getFrettyQueryString } from "../../utils";
import { ScaleType } from "tonal";
import { getInitialParamsDict } from "../../utils";
import { tonalMap } from "../../enums/tonal";


const ChordSelectors = ({ note, onChangeNote, tonality, onChangeTonality }) => {
  const keyOptions = [
    ...Object.values(tonalMap).map((key) => {
      return {
        value: key,
        label: formatFromChordDb(key),
      };
    }),
  ];

  const tonalityOptions = note
    ? ScaleType.all().map((suf) => {
      return {
        value: suf.name,
        label: capitalizeWord(suf.name),
      };
    })
    : [];

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} lg={12}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Note"
          defaultValue={note}
          value={note}
          onChange={(value, option) => onChangeNote(value)}
          allowClear
          options={keyOptions}
        />
      </Col>
      <Col xs={24} lg={12}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Scale Type"
          defaultValue={tonality}
          value={tonality}
          onChange={(value, option) => onChangeTonality(value)}
          allowClear
          options={tonalityOptions}
          disabled={!note}
        />
      </Col>
    </Row>
  );
};

const ScaleExporer = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  const initialParams = getInitialParamsDict(location.search);

  const [note, setNote] = useState(initialParams.note);
  const [tonality, setTonality] = useState(initialParams.tonality);

  const frettyQueryString = getFrettyQueryString(`${note} ${tonality}`, false)

  const onChangeNote = (newNote) => {
    const newFilters = {
      note: newNote,
      tonality,
    };
    const newParamString = new URLSearchParams(newFilters).toString();
    const newUrl =
      location.pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setNote(newNote);
  };

  const onChangeTonality = (newTonality) => {
    const newFilters = {
      note,
      tonality: newTonality,
    };
    const newParamString = new URLSearchParams(newFilters).toString();
    const newUrl =
      location.pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setTonality(newTonality);
  };

  const handleClear = () => {
    setNote(null);
    setTonality(null);
    window.history.replaceState(null, "", location.pathname);
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <Card size="small">
          <Typography.Title level={4}>Scale Explorer</Typography.Title>
          <Typography.Paragraph>
            The Scale Explorer allows you to see an interactive fretboard that maps out scales for any given note and tonality.
          </Typography.Paragraph>
          <ChordSelectors
            note={note}
            tonality={tonality}
            onChangeNote={onChangeNote}
            onChangeTonality={onChangeTonality}
          />

          <iframe src={"https://fretty.jamtrackers.com" + frettyQueryString} height="250" width="100%" style={{ border: "none" }}></iframe>

          {note && tonality && (
            <Row justify="center">
              <Col>
                <Space>
                  <Button onClick={handleClear} type="link">Reset</Button>
                </Space>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  )
};

export default ScaleExporer;