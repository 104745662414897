export const getAdFrequency = () => {
  return 6; // TODO add some logic here
}

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertToMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const centiseconds = Math.floor(
    (remainingSeconds - Math.floor(remainingSeconds)) * 100
  );

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(Math.floor(remainingSeconds)).padStart(
    2,
    "0"
  );
  const formattedCentiseconds = String(centiseconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}:${formattedCentiseconds}`;
};

export const formatFromChordDb = (chord) => {
  let modified;
  modified = chord.replace("#", "♯");
  modified = modified.replace("sharp", "♯");
  modified = modified.replace("b", "♭");

  return modified;
};

export const roundToNearestQuarter = (number) => Math.round(number * 4) / 4;

export const getInitialParamsDict = (queryString) => {
  const initialParams = new URLSearchParams(queryString);
  const paramsDict = {};
  initialParams.forEach((value, key) => (paramsDict[key] = value));
  return paramsDict;
};

export const getDateString = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const capitalizeWord = (word) => {
  if (typeof word !== "string" || word.length === 0) {
    return "";
  }

  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getTimeAgo = (datetimeString) => {
  const datetime = new Date(datetimeString);
  const now = new Date();
  const elapsedMilliseconds = now - datetime;

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;

  if (elapsedMilliseconds < minute) {
    return "Now";
  } else if (elapsedMilliseconds < hour) {
    const minutes = Math.floor(elapsedMilliseconds / minute);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (elapsedMilliseconds < day) {
    const hours = Math.floor(elapsedMilliseconds / hour);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (elapsedMilliseconds < week) {
    const days = Math.floor(elapsedMilliseconds / day);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (elapsedMilliseconds < day * 90) {
    const elapsedDays = Math.floor(elapsedMilliseconds / day);
    if (elapsedDays < 30) {
      const weeks = Math.floor(elapsedDays / 7);
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else {
      const months = Math.floor(elapsedDays / 30);
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  } else {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return datetime.toLocaleDateString(undefined, options);
  }
};

export const isValidYouTubeUrl = (url) => {
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([a-zA-Z0-9_-]{11})(\S+)?$/;
  return regex.test(url);
}

export const getFrettyQueryString = (scale, forBass) => {
  const [scaleTonic, ...rest] = scale.split(" ")
  const scaleType = rest.join(" ")
  console.log(scaleTonic, scaleType, scale)
  const usrTuning = forBass ? "E A D G" : "E A D G B E";
  return "?" + Object.entries({ usrTuning, scaleTonic, scaleType })
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}