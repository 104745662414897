import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
  Upload,
  List,
  Modal,
  message,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ReadOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { createCourse, deleteCourse, deleteLesson, getCourse, updateCourse, updateLesson } from "../../services/courses";
import AuthContext from "../../contexts/auth-context";
import Dragger from "antd/es/upload/Dragger";
import { convertToBase64 } from "../../utils";
import FeedHeader from "../../components/typography/feed-header";
import styles from "./course-edit.module.scss";

const CourseEditLoading = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 1 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 3 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 10 }} />
        </Card>
      </Col>
    </Row>
  );
};

const SectionCardTitle = ({ title, desc }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      <Typography.Text type="secondary">
        <small>{desc}</small>
      </Typography.Text>
    </div>
  );
};

const CourseEditPage = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { token, tokenClaim } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState(null);
  const [newPhotoData, setNewPhotoData] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const editOrNewTitle = courseId ? "Edit Course" : "New Course";

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const courseData = await getCourse(token, courseId);

      if (courseData.user.username !== tokenClaim.username) {
        navigate("/");
      }

      const { title, description, photo, lessons, is_published } = courseData;

      setTitle(title);
      setDescription(description);
      setPhoto(photo);
      setLessons(lessons.sort((a, b) => a.order - b.order));
      setIsPublished(is_published);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCoverPhotoSelect = async (file) => {
    const acceptedMimeTypes = ["image/jpeg", "image/png"];
    const isJpgOrPng = acceptedMimeTypes.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return;
    }

    const base64 = await convertToBase64(file);
    setNewPhotoData(base64);
    return false; // prevent it from making upload call
  };

  const onClickReorderLesson = (lesson, moveTo) => {
    const currentIndex = lessons.indexOf(lesson);
    const newIndex = currentIndex + moveTo;
    const filteredLessons = lessons.filter((l) => l !== lesson)
    filteredLessons.splice(newIndex, 0, lesson)

    setLessons(filteredLessons.map((l, i) => ({ ...l, order: i })))
  }

  const onClickDeleteLesson = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      await deleteLesson(token, lessonToDelete);
      const newLessons = lessons.filter((l) => l !== lessonToDelete);
      setLessons(newLessons);
      setLessonToDelete(null)
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  const onSaveCourse = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const courseData = {
        title,
        description,
        is_published: isPublished,
      };
      let newCourseData;

      if (courseId) {
        if (newPhotoData) courseData.photo = newPhotoData;
        await updateCourse(token, courseId, courseData);
        lessons.forEach(async (l) => {
          await updateLesson(token, l.id, { order: l.order })
        })
      } else {
        courseData.photo = newPhotoData;
        newCourseData = await createCourse(token, courseData);
      }

      notification.open({
        message: "Course Saved Successfully",
        placement: "bottomRight",
      });
      navigate(`/courses/${courseId ? courseId : newCourseData.id}`);
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to save this course at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    try {
      await deleteCourse(token, { id: courseId });
      notification.open({
        message: "Course Deleted",
        description:
          `${title} has been deleted.`,
        placement: "bottomRight",
      });
      navigate("/courses/recent");
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to delete this course at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (courseId) {
      loadData();
    } else {
      setIsLoading(false);
    }
  }, [courseId]);

  if (hasError) {
    return (
      <Alert
        type="error"
        message="Something went wrong..."
        description="An error occured, we are unable to load the blog. Please try again
        later."
      />
    );
  }

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <FeedHeader icon={<ReadOutlined />} title={editOrNewTitle} />
        </Col>
      </Row>
      {isLoading ? (
        <CourseEditLoading />
      ) : (
        <>
          <Form
            initialValues={{
              title,
              description,
            }}
            onFinish={onSaveCourse}
          >
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Card size="small">
                  <Typography.Title level={5}>Title</Typography.Title>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please add a title.",
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength={400}
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small">
                  <SectionCardTitle
                    title="Description"
                    desc="A brief description of your course that entices users to explore it."
                  />
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please add a description.",
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={500}
                      style={{ marginBottom: 12 }}
                      showCount
                      autoSize
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small">
                  <SectionCardTitle
                    title="Cover Photo"
                    desc="A decorative image to accompany your course."
                  />
                  <>
                    {newPhotoData || photo ? (
                      <>
                        <img
                          src={newPhotoData || photo}
                          alt={title}
                          className={styles["course-cover-photo"]}
                        />
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <Upload
                            name="avatar"
                            showUploadList={false}
                            beforeUpload={handleCoverPhotoSelect}
                            accept="image/png, image/jpeg"
                          >
                            <Button type="link" size="small">
                              Change Photo
                            </Button>
                          </Upload>
                        </div>
                      </>
                    ) : (
                      <Dragger
                        showUploadList={false}
                        beforeUpload={handleCoverPhotoSelect}
                        accept="image/png, image/jpeg"
                      >
                        <Typography.Text type="secondary">
                          Select Photo
                        </Typography.Text>
                      </Dragger>
                    )}
                  </>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small">
                  <SectionCardTitle
                    title="Lessons"
                    desc={
                      courseId
                        ? "Add and edit the lessons for this course."
                        : "You will be able to add and edit lessons after this course has been saved and created."
                    }
                  />
                  {courseId && (
                    <>
                      <Row>
                        <Col span={24}>
                          <List size="large" dataSource={lessons}>
                            {lessons.map((l, index) => (
                              <List.Item
                                key={l.id}
                                style={{ marginRight: -10, marginLeft: -10 }}
                                actions={[
                                  <Row justify="space-between" style={{ width: 80 }}>
                                    <Col>
                                      {index !== lessons.length - 1 &&
                                        <>
                                          <Button
                                            type="text"
                                            size="small"
                                            onClick={() => onClickReorderLesson(l, 1)}
                                          >
                                            <ArrowDownOutlined />
                                          </Button>
                                        </>
                                      }
                                    </Col>
                                    <Col>
                                      {index !== 0 &&
                                        <>
                                          < Button
                                            type="text"
                                            size="small"
                                            onClick={() => onClickReorderLesson(l, -1)}
                                          >
                                            <ArrowUpOutlined />
                                          </Button>
                                        </>
                                      }
                                    </Col>
                                  </Row>
                                ]}
                              >
                                <div>
                                  <Space>
                                    <Typography.Text
                                      onClick={() => navigate(`/courses/${courseId}/lessons/${l.id}`)}
                                      className={styles["lesson-title"]}
                                    >
                                      <b>{l.title}</b>
                                    </Typography.Text>
                                    {!l.is_published && (
                                      <Typography.Text type="secondary">
                                        <small>
                                          DRAFT
                                        </small>
                                      </Typography.Text>
                                    )}
                                  </Space>
                                  <div>
                                    <Space>
                                      <Typography.Text
                                        type="secondary"
                                        onClick={() => navigate(`/courses/${courseId}/lessons/${l.id}/edit`)}
                                        className={styles["lesson-actions"]}
                                      >
                                        <small>
                                          Edit
                                        </small>
                                      </Typography.Text>
                                      <Typography.Text
                                        type="danger"
                                        onClick={() => setLessonToDelete(l)}
                                        className={styles["lesson-actions"]}
                                      >
                                        <small>
                                          Delete
                                        </small>
                                      </Typography.Text>
                                      {lessonToDelete && (
                                        <Modal
                                          title="Delete Lesson"
                                          open={lessonToDelete}
                                          onOk={onClickDeleteLesson}
                                          onCancel={() => setLessonToDelete(null)}
                                          okType="danger"
                                        >
                                          <Typography.Paragraph>
                                            Are you sure you want to delete the lesson <b>{lessonToDelete.title}</b>? It will not be possible to recover it.
                                          </Typography.Paragraph>
                                        </Modal>
                                      )}
                                    </Space>
                                  </div>
                                </div>
                              </List.Item>
                            ))}
                          </List>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col>
                          <Button onClick={() => navigate(`/courses/${courseId}/lessons/new`)} type="link">
                            Create Lesson
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small">
                  <Row justify="space-between" align="middle">
                    <Col xs={24} lg={16}>
                      <SectionCardTitle
                        title="Publish"
                        desc={
                          isPublished
                            ? "This course will be published and available for others to explore. Toggle the switch below and click save to mark it as a draft."
                            : "This course will be saved as a draft, only visible to you. Toggle the switch below and click save to publish it."
                        }
                      />
                    </Col>
                    <Col span="auto">
                      <Switch
                        checked={isPublished}
                        onChange={() => setIsPublished((prev) => !prev)}
                        checkedChildren="PUBLISHED"
                        unCheckedChildren="DRAFT"
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24} style={{ textAlign: "right" }}>
                <Row justify="space-between">
                  <Col>
                    {courseId && (
                      <>
                        <Button danger onClick={() => setDeleteModal(true)} type="text">
                          Delete Course
                        </Button>
                        <Modal
                          open={deleteModal}
                          title="Delete Course"
                          onCancel={() => setDeleteModal(false)}
                          onOk={onConfirmDelete}
                          okText="Delete"
                          okType="danger"
                        >
                          <Typography.Text>Are you sure you want to delete the course <b>{title}</b>? All lessons will also be deleted.</Typography.Text>
                        </Modal>
                      </>
                    )}
                  </Col>
                  <Col>
                    <Space>
                      <Button onClick={() => navigate("/courses/recent")}>
                        Cancel
                      </Button>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form >
        </>
      )}
    </>
  );
};

export default CourseEditPage;
