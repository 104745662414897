import React from "react";
import { Space, Typography } from "antd";

const FeedHeader = ({ icon, title, style }) => <Space style={style}>
    <Typography.Title level={4}>
        <Space>
            {icon && icon}
            {title}
        </Space>
    </Typography.Title>
</Space>;

export default FeedHeader;